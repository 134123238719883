import { FC, useEffect, useState } from 'react';
import Layout from 'layouts/Layout';
import PageTitle from 'components/PageTitle';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import Table from 'components/Table';
import { Button } from 'react-bootstrap';
import { RouteComponentProps, useHistory } from 'react-router';
import { show, EducationalBackgroundGroup } from 'libs/apiClient/admin/educationalBackgroundGroups';
import { editEducationalBackgroundGroupsPathCreator } from 'routes/educationalBackgroundGroups/path';
import Forbidden from 'pages/forbidden';

type urlProps = {} & RouteComponentProps<{ id: string }>;
type School = { degree: string, name: string }

const Show: FC<urlProps> = (props: urlProps) => {
  const history = useHistory()
  const [educationalBackgroundGroup, setEducationalBackgroundGroup] = useState<EducationalBackgroundGroup>()
  const [schools, setSchools] = useState<School[]>();
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);
  const educationalBackgroundGroupId = props.match.params.id

  useEffect(() => {
    const forbiddenCallback = () => { setIsForbidden(true); }

    const setResponseData = (responseData: EducationalBackgroundGroup) => {
      const schoolList: School[] = [];
      responseData.schools.forEach((school: School) => {
        if (!schoolList.find(item => item.name == school.name)) {
          schoolList.push({ name: school.name, degree: school.degree })
        }
      });

      setSchools(schoolList);
      setEducationalBackgroundGroup(responseData);
    }

    show(educationalBackgroundGroupId, setResponseData, forbiddenCallback)
  }, []);

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="学歴グループ詳細" />
        <Panel className='pb-2'>
          <PanelTitle title='基本情報' />
          <p>id: {educationalBackgroundGroup?.id}</p>
          <p>学歴グループ名: {educationalBackgroundGroup?.name}</p>
        </Panel>
        <Panel>
          <PanelTitle title='学校一覧'>
            <Button variant="primary" onClick={() => history.push(editEducationalBackgroundGroupsPathCreator(educationalBackgroundGroupId))}>編集</Button>
          </PanelTitle>
          <Table>
            <thead>
              <tr>
                <th>区分</th>
                <th>学校名</th>
              </tr>
            </thead>
            <tbody>
              {
                schools?.map(school => {
                  return (
                    <tr key={school.name}>
                      <td className="align-middle">{school.degree}</td>
                      <td className="align-middle">{school.name}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Show;