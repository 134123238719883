import { FC, Dispatch, useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import DateTime from 'components/DateTime';
import { Prefecture } from 'libs/apiClient/prefectures';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import {
  indexPath,
  CustomFormItem,
  ItemType,
  TextFieldType,
  TextareaType,
  MultipleItemType,
  MultipleItem
} from 'libs/apiClient/admin/customFormItems';
import { 
  createPath,
  RequestData,
  Errors
} from 'libs/apiClient/admin/entries';
import { get } from 'libs/apiClient';

type FormProps = {
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  onSubmit: () => void;
  errors: Errors;
  setErrors: React.Dispatch<React.SetStateAction<Errors>>;
  userId: number|string|null;
  scheduleId: number|string|null;
  prefectures: Prefecture[];
  entryFormId: number|string|null;
  onClose: () => void;
}

type CustomFormItemAnswers = {
  customFormItemId: number | string;
  value: number | string;
}

const UserEntryForm: FC<FormProps> = (props) => {
  const{
    formData,
    setFormData, 
    onSubmit,
    errors,
    setErrors,
    userId,
    scheduleId,
    prefectures,
    entryFormId,
    onClose
  } = props;
  const [customFormItems, setCustomFormItems] = useState<CustomFormItem[]>([]);

  useEffect(() => {
    // カスタムフォームアイテム一覧取得
    get(indexPath(entryFormId), 'customFormItems', setCustomFormItems);
  }, [entryFormId]);

  const onChange = (value: RequestData[keyof RequestData], key: keyof RequestData): void => {
    const data = { ...formData, [key]: value }
    setFormData(data);
  }

  return (
    <Panel className='pb-4'>
    <PanelTitle title={ `エントリーフォーム(スケジュールID: ${scheduleId})` } />
      <Form noValidate className='col-6'>
        { customFormItems &&(
          customFormItems.map(customFormItem => {
            switch (customFormItem.fieldContext) {
              case 'text_field':
                return <TextField key={ `textfield-${customFormItem.id}` } customFormItem={customFormItem} formData={ formData } setFormData={ setFormData } errors={ errors } />
              case 'textarea':
                return <TextArea key={ `textarea-${customFormItem.id}` } customFormItem={customFormItem} formData={ formData } setFormData={ setFormData } errors={ errors } />
              case 'radio':
                return <Radio key={ `radio-${customFormItem.id}` } customFormItem={customFormItem} formData={ formData } setFormData={ setFormData } errors={ errors } />
              case 'checkbox':
                return <Checkbox key={ `checkbox-${customFormItem.id}` } customFormItem={customFormItem} formData={ formData } setFormData={ setFormData } errors={ errors } />
              case 'selectbox':
                return <Selectbox key={ `selectbox-${customFormItem.id}` } customFormItem={customFormItem} formData={ formData } setFormData={ setFormData } errors={ errors } />
              case 'agreement':
                return <Agreement key={ `selectbox-${customFormItem.id}` } customFormItem={customFormItem} formData={ formData } setFormData={ setFormData } errors={ errors } />
              case 'full_address':
                return <FullAddress key={ `fulladdress-${customFormItem.id}` } prefectures={prefectures} customFormItem={customFormItem} formData={ formData } setFormData={ setFormData } errors={ errors } />
              case 'datetime_field':
                return <DatetimeField key={ `datetimefield-${customFormItem.id}` } customFormItem={customFormItem} formData={ formData } setFormData={ setFormData } errors={ errors } />
            }
          })
        )}

        <Form.Group controlId='entryRoute'>
          <Form.Label>登録経路を選択してください<span className="text-danger">（必須）</span></Form.Label>          
            <Form.Control
              as="select"
              custom
              onChange={ e => onChange(e.target.value, 'entryRoute') }
              isInvalid={ (errors.entryRoute && errors.entryRoute?.length > 0)
                        || (errors.entryConditions && errors.entryConditions?.length > 0) 
                        || (errors.customFormItemAnswers && errors.customFormItemAnswers?.length > 0)
                        }
            >
              <option></option>
              <option value='架電'>架電</option>
              <option value='クライアント問い合わせ'>クライアント問い合わせ</option>
              <option value='ユーザー問い合わせ'>ユーザー問い合わせ</option>
              <option value='アプリ内成約'>アプリ内成約</option>
            </Form.Control>
          <Form.Control.Feedback type="invalid">
            { errors.entryRoute?.map((err, i) => <p key={ `error-entryRoute-${i}` }>{ err }</p>) }
            { errors.entryConditions?.map((err, i) => <p key={ `error-entryConditions-${i}` }>{ err }</p>) }
            { errors.customFormItemAnswers?.map((err, i) => <p key={ `error-customFormItemAnswers-${i}` }>{ err }</p>) }
            { errors.schoolId?.map((err, i) => <p key={ `error-schoolId-${i}` }>{ err }</p>) }
            { errors.schoolSpecialtyGroupId?.map((err, i) => <p key={ `error-schoolSpecialtyGroupId-${i}` }>{ err }</p>) }
            { errors.gender?.map((err, i) => <p key={ `error-gender-${i}` }>{ err }</p>) }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Button type="button" className='mr-2' variant="outline-secondary" onClick={ onClose }>キャンセル</Button>
          <Button type="button" variant='outline-success' onClick={ onSubmit }>登録</Button>
        </Form.Group>
      </Form>
    </Panel>
  )
}

type TextFieldProps = {
  customFormItem: CustomFormItem;
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
}

const TextField: FC<TextFieldProps> = (props) => {
  const { customFormItem, formData, setFormData, errors } = props;
  const itemDetail: TextFieldType = customFormItem.itemDetail as TextFieldType;
  const [textLength, setTextLength] = useState<number>(0);
  const maxlength: number|null = itemDetail.maxlength ? Number(itemDetail.maxlength) : null;
  const minlength: number|null = itemDetail.minlength ? Number(itemDetail.minlength) : null;

  const onChange = (id: number | string, value: number | string) => {
    const newCustomFormItemAnswers: CustomFormItemAnswers = {['customFormItemId']: id, ['value']: value}
    const newFormData = { ...formData };
    const customFormItemAnswerIndex = newFormData.customFormItemAnswers.findIndex(item => {
      return item.customFormItemId == id
    })
    if (customFormItemAnswerIndex == -1){
      newFormData.customFormItemAnswers.push(newCustomFormItemAnswers)
    } else {
      newFormData.customFormItemAnswers[customFormItemAnswerIndex] = newCustomFormItemAnswers;
    }
    setFormData(newFormData);
    setTextLength(String(value).length);
  }

  return (
    <>
      <Form.Group>
        <Form.Label>
          {customFormItem.label}
          {customFormItem.isRequired && <span className="text-danger">（必須）</span> }
        </Form.Label>
         
        <Form.Control
          type='text'
          name={ customFormItem.label }
          key={ customFormItem.id }
          placeholder={ itemDetail.placeholder }
          onChange={ e => onChange(customFormItem.id, e.target.value) }
          isInvalid={ (maxlength ? textLength>maxlength : false) || (minlength ? textLength<minlength: false) }
        />
        <Form.Control.Feedback type="invalid">
          { <p key={ `error-textFieldLength-${customFormItem.id}` }>{ (minlength ? `${minlength}文字以上` : '') + (maxlength ? `${maxlength}文字以内` : '') + ` で入力してください` }</p> }
        </Form.Control.Feedback>
      </Form.Group>
    </>
  )
}

type TextAreaProps = {
  customFormItem: CustomFormItem;
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
}

const TextArea: FC<TextAreaProps> = (props) => {
  const { customFormItem, formData, setFormData, errors } = props;
  const itemDetail: TextareaType = customFormItem.itemDetail as TextareaType;
  const [textLength, setTextLength] = useState<number>(0);
  const maxlength: number|null = itemDetail.maxlength ? Number(itemDetail.maxlength) : null;
  const minlength: number|null = itemDetail.minlength ? Number(itemDetail.minlength) : null;

  const onChange = (id: number | string, value: number | string) => {
    const newCustomFormItemAnswers: CustomFormItemAnswers = {['customFormItemId']: id, ['value']: value}
    const newFormData = { ...formData };
    const customFormItemAnswerIndex = newFormData.customFormItemAnswers.findIndex(item => {
      return item.customFormItemId == id
    })
    if (customFormItemAnswerIndex == -1){
      newFormData.customFormItemAnswers.push(newCustomFormItemAnswers)
    } else {
      newFormData.customFormItemAnswers[customFormItemAnswerIndex] = newCustomFormItemAnswers;
    }
    setFormData(newFormData);
    setTextLength(String(value).length);
  }

  return (
    <>
      <Form.Group>
        <Form.Label>
          {customFormItem.label}
          {customFormItem.isRequired && <span className="text-danger">（必須）</span> }
        </Form.Label>
        <Form.Control
          as='textarea'
          name={ customFormItem.label }
          key={ customFormItem.id }
          placeholder={ itemDetail.placeholder }
          onChange={ e => onChange(customFormItem.id, e.target.value) }
          isInvalid={ (maxlength ? textLength>maxlength : false) || (minlength ? textLength<minlength: false) }
        />
        <Form.Control.Feedback type="invalid">
          { <p key={ `error-textAreaLength-${customFormItem.id}` }>{ (minlength ? `${minlength}文字以上` : '') + (maxlength ? `${maxlength}文字以内` : '') + ` で入力してください` }</p> }
        </Form.Control.Feedback>
      </Form.Group>
    </>
  )
}

type RadioProps = {
  customFormItem: CustomFormItem;
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
}

const Radio: FC<RadioProps> = (props) => {
  const { customFormItem, formData, setFormData, errors } = props;
  const itemDetail: MultipleItemType = customFormItem.itemDetail as MultipleItemType;

  useEffect(() => {
    // defaultCheckedのアイテムを取得
    // ToDo: 入力したアイテムが上書きされないか確認
    { itemDetail.items && (
      itemDetail.items.map(item => {
        item.isDefault && onChange(customFormItem.id, item.value)
      })
    )}
  }, []);

  const onChange = (id: number | string, value: number | string) => {
    const newCustomFormItemAnswers: CustomFormItemAnswers = {['customFormItemId']: id, ['value']: value}
    const newFormData = { ...formData };
    const customFormItemAnswerIndex = newFormData.customFormItemAnswers.findIndex(item => {
      return item.customFormItemId == id
    })
    if (customFormItemAnswerIndex == -1){
      newFormData.customFormItemAnswers.push(newCustomFormItemAnswers)
    } else {
      newFormData.customFormItemAnswers[customFormItemAnswerIndex] = newCustomFormItemAnswers;
    }
    setFormData(newFormData);
  }

  return (
    <>
      <Form.Group>
        <Form.Label>
          {customFormItem.label}
          {customFormItem.isRequired && <span className="text-danger">（必須）</span> }
        </Form.Label>
        <div className={ `` }>
          { itemDetail.items && (
            itemDetail.items.map(item => {
              return (
                <Form.Check
                  key={ `radio-key-${item.value}` }
                  inline
                  id={ item.value }
                  type={ 'radio' }
                  name={ customFormItem.label }
                  label={ item.label }
                  defaultChecked={ item.isDefault }
                  onChange={ _e => onChange(customFormItem.id, item.value) }
                />            
              )
            })
          )}
        </div>
      </Form.Group>
    </>
  )
}

type SelectboxProps = {
  customFormItem: CustomFormItem;
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
}

const Selectbox: FC<SelectboxProps> = (props) => {
  const { customFormItem, formData, setFormData, errors } = props;
  const itemDetail: MultipleItemType = customFormItem.itemDetail as MultipleItemType;

  useEffect(() => {
    // defaultCheckedのアイテムを取得
    // ToDo: 入力したアイテムが上書きされないか確認
    { itemDetail.items && (
      itemDetail.items.map(item => {
        item.isDefault && onChange(customFormItem.id, item.value)
      })
    )}
  }, []);

  const onChange = (id: number | string, value: number | string) => {
    const newCustomFormItemAnswers: CustomFormItemAnswers = {['customFormItemId']: id, ['value']: value}
    const newFormData = { ...formData };
    const customFormItemAnswerIndex = newFormData.customFormItemAnswers.findIndex(item => {
      return item.customFormItemId == id
    })
    if (customFormItemAnswerIndex == -1){
      newFormData.customFormItemAnswers.push(newCustomFormItemAnswers)
    } else {
      newFormData.customFormItemAnswers[customFormItemAnswerIndex] = newCustomFormItemAnswers;
    }
    setFormData(newFormData);
  }

  return (
    <>
      <Form.Group>
        <Form.Label>
          {customFormItem.label}
          {customFormItem.isRequired && <span className="text-danger">（必須）</span> }
        </Form.Label>
        <Form.Control
          as="select"
          custom
          onChange={ e => onChange(customFormItem.id, e.target.value) }
          defaultValue={(itemDetail.items.filter(item => item.isDefault))[0]?.value}
        >
          <option></option>
          { itemDetail.items && (
            itemDetail.items.map(item => {
              return (
                <option
                  key={ item.value }
                  value={ item.value }
                >
                  { item.label }
                </option>                
              )
            })
          )}
        </Form.Control>
      </Form.Group>
    </>
  )
}

type CheckboxProps = {
  customFormItem: CustomFormItem;
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
}

const Checkbox: FC<CheckboxProps> = (props) => {
  const { customFormItem, formData, setFormData, errors } = props;
  const itemDetail: MultipleItemType = customFormItem.itemDetail as MultipleItemType;
  const [targetValues, setTargetValues] = useState<string[]>([]);

  useEffect(() => {
    // defaultCheckedのアイテムを取得
    // ToDo: 入力したアイテムが上書きされないか確認
    { itemDetail.items && (
      itemDetail.items.map(item => {
        item.isDefault && onCheck(customFormItem.id, item.value)
      })
    )}
  }, []);

  const onChange = (id: number | string, value: number | string) => {
    const newCustomFormItemAnswers: CustomFormItemAnswers = {['customFormItemId']: id, ['value']: value}
    const newFormData = { ...formData };
    const customFormItemAnswerIndex = newFormData.customFormItemAnswers.findIndex(item => {
      return item.customFormItemId == id
    })
    if (customFormItemAnswerIndex == -1){
      newFormData.customFormItemAnswers.push(newCustomFormItemAnswers)
    } else {
      newFormData.customFormItemAnswers[customFormItemAnswerIndex] = newCustomFormItemAnswers;
    }    
    setFormData(newFormData);
  }

  const onCheck = (customFormItemId: number | string, value: string) => {
    const newTargetValues = [...targetValues];
    // ToDo: valueがすでにnewTargetValues内に存在しないかチェック
    newTargetValues.push(value);
    setTargetValues(newTargetValues);

    // 配列を|で結合
    onChange(customFormItemId, newTargetValues.join('|'))
  }

  const onUnCheck = (customFormItemId: number | string, value: string) => {
    const newTargetValues = [...targetValues].filter(targetValue => !(targetValue == value));
    setTargetValues(newTargetValues);

    // 配列を|で結合
    onChange(customFormItemId, newTargetValues.join('|'))
  }

  return (
    <>
      <Form.Group>
        <Form.Label>
          {customFormItem.label}
          {customFormItem.isRequired && <span className="text-danger">（必須）</span> }
        </Form.Label>
        <div className={ `` }>
          { itemDetail.items && (
            itemDetail.items.map(item => {
              return (
                <Form.Check
                  key={ `check-key-${item.label}-${item.value}` }
                  inline
                  id={ `check-id-${customFormItem.id}-${item.value}` }
                  type={ 'checkbox' }
                  name={ customFormItem.label }
                  label={ item.label }
                  value={ item.value }
                  defaultChecked={ item.isDefault }
                  onChange={ e => e.target.checked ? onCheck(customFormItem.id, e.target.value) : onUnCheck(customFormItem.id, e.target.value) }
                />
              )
            })
          )}
        </div>
      </Form.Group>
    </>
  )
}

type AgreementProps = {
  customFormItem: CustomFormItem;
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
}

const Agreement: FC<AgreementProps> = (props) => {
  const { customFormItem, formData, setFormData, errors } = props;
  const itemDetail: MultipleItem = customFormItem.itemDetail as MultipleItem;

  useEffect(() => {
    // defaultCheckedのアイテムを取得
    // ToDo: 入力したアイテムが上書きされないか確認
    itemDetail.isDefault && onCheck(customFormItem.id, itemDetail.value)
  }, []);

  const onCheck = (id: number | string, value: number | string) => {
    const newCustomFormItemAnswers: CustomFormItemAnswers = {['customFormItemId']: id, ['value']: value}
    const newFormData = { ...formData };
    const customFormItemAnswerIndex = newFormData.customFormItemAnswers.findIndex(item => {
      return item.customFormItemId == id
    })
    if (customFormItemAnswerIndex == -1){
      newFormData.customFormItemAnswers.push(newCustomFormItemAnswers)
    } else {
      newFormData.customFormItemAnswers[customFormItemAnswerIndex] = newCustomFormItemAnswers;
    }
    setFormData(newFormData);
  }

  const onUnCheck = (customFormItemId: number | string) => {
    onCheck(customFormItemId, '')
  }

  return (
    <>
      <Form.Group>
        <Form.Label>
          {customFormItem.label}
          {customFormItem.isRequired && <span className="text-danger">（必須）</span> }
        </Form.Label>
        <Form.Check
          key={ `agreement-key-${itemDetail.label}-${itemDetail.value}` }
          inline
          id={ `agreement-id-${customFormItem.id}-${itemDetail.value}` }
          type={ 'checkbox' }
          label={ itemDetail.label }
          value={ itemDetail.value }
          defaultChecked={ itemDetail.isDefault }
          onChange={ e => e.target.checked ? onCheck(customFormItem.id, e.target.value) : onUnCheck(customFormItem.id) }
        />
      </Form.Group>
    </>
  )
}

type FullAddressProps = {
  customFormItem: CustomFormItem;
  prefectures: Prefecture[];
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
}

type Address = {
  postCode: string;
  prefectureName: string;
  address1: string;
  address2: string;
}

const FullAddress: FC<FullAddressProps> = (props) => {
  const { customFormItem, prefectures, formData, setFormData, errors } = props;
  const addressDataTemplate = {
    postCode: '',
    prefectureName: '',
    address1: '',
    address2: '',
  }
  const [addressData, setAddressData] = useState<Address>(addressDataTemplate);


  const setFullAddress = (id: number | string, value: number | string) => {
    const newCustomFormItemAnswers: CustomFormItemAnswers = {['customFormItemId']: id, ['value']: value}
    const newFormData = { ...formData };
    const customFormItemAnswerIndex = newFormData.customFormItemAnswers.findIndex(item => {
      return item.customFormItemId == id
    })
    if (customFormItemAnswerIndex == -1){
      newFormData.customFormItemAnswers.push(newCustomFormItemAnswers)
    } else {
      newFormData.customFormItemAnswers[customFormItemAnswerIndex] = newCustomFormItemAnswers;
    }
    setFormData(newFormData);
  }  

  const onChange = (value: Address[keyof Address], key: keyof Address): void => {
    const data = { ...addressData, [key]: value }
    setAddressData(data);

    // 郵便番号と住所を|で結合
    const joinedAddress = data.postCode.concat('|', data.prefectureName, data.address1, data.address2)
    setFullAddress(customFormItem.id, joinedAddress)
  }

  return (
    <>
      <Form.Group>
        <Form.Label>
          郵便番号
          {customFormItem.isRequired && <span className="text-danger">（必須）</span> }
        </Form.Label>
        <Form.Control
            type='text'
            name="postCode"
            onChange={ e => onChange(e.target.value, 'postCode') }
            placeholder={ '1234567' }
          />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          お住いの都道府県
          {customFormItem.isRequired && <span className="text-danger">（必須）</span> }
        </Form.Label>
          <Form.Control
            as="select"
            custom
            onChange={ e => {onChange(e.target.value, 'prefectureName')} }
          >
            <option></option>
            {
              prefectures.map((prefecture: Prefecture) => {
                return (
                  <option
                    key={ `prefecture-${prefecture.id}` }
                    value={ prefecture.name }
                  >
                    { prefecture.name }
                  </option>)
              })
            }
          </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>
          市区町村
          {customFormItem.isRequired && <span className="text-danger">（必須）</span> }
        </Form.Label>
        <Form.Control
            type='text'
            name="address1"
            onChange={ e => onChange(e.target.value, 'address1') }
            placeholder={ '新宿区' }
          />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          番地・建物名
          {customFormItem.isRequired && <span className="text-danger">（必須）</span> }
        </Form.Label>
        <Form.Control
            type='text'
            name="address2"
            onChange={ e => onChange(e.target.value, 'address2') }
            placeholder={ '西新宿１丁目２５−１' }
          />
      </Form.Group>
    </>
  )
}

type DatetimeFieldProps = {
  customFormItem: CustomFormItem;
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
}

const DatetimeField: FC<DatetimeFieldProps> = (props) => {
  const { customFormItem, formData, setFormData, errors } = props;

  const onChange = (id: number | string, value: number | string) => {
    const newCustomFormItemAnswers: CustomFormItemAnswers = {['customFormItemId']: id, ['value']: value}
    const newFormData = { ...formData };
    const customFormItemAnswerIndex = newFormData.customFormItemAnswers.findIndex(item => {
      return item.customFormItemId == id
    })
    if (customFormItemAnswerIndex == -1){
      newFormData.customFormItemAnswers.push(newCustomFormItemAnswers)
    } else {
      newFormData.customFormItemAnswers[customFormItemAnswerIndex] = newCustomFormItemAnswers;
    }    
    setFormData(newFormData);
  }

  return (
    <>
      <Form.Group>
        <Form.Label>
          {customFormItem.label}
          {customFormItem.isRequired && <span className="text-danger">（必須）</span> }
        </Form.Label>
        <DateTime
          key={ customFormItem.id }
          className={`col-6 p-0`}
          name={ customFormItem.label }
          onChange={ value => onChange(customFormItem.id, value) }
        />      
      </Form.Group>
    </>
  )
}

export default UserEntryForm;
