import { FC, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { showPath, createPath, updatePath, RemindMail, RequestData, Response, Errors } from 'libs/apiClient/admin/remindMail';
import { show, create, update } from 'libs/apiClient';

type Props = {
  remindMail: RemindMail | undefined;
  campaignId: string | number;
}

const RemindMailForm: FC<Props> = (props) => {
  const { remindMail, campaignId } = props;
  const [formData, setFormData] = useState<RequestData>({
    title: remindMail?.title || '',
    body: remindMail?.body || '',
    // htmlBody: remindMail?.body || '',
    campaignId: campaignId,
    sendTimings: remindMail?.sendTimings || [],
  });
  const [errors, setErrors] = useState<Errors>({})
  const [isUpdate, setIsUpdate] = useState<boolean>(Boolean(remindMail))
  const [remindMailId, setRemindMailId] = useState<string|number|undefined>(remindMail?.id)

  const onChange = (value: string, name: 'title' | 'body' | 'htmlBody') => {
    const newFormData = { ...formData }
    newFormData[name] = value;
    setFormData(newFormData);
  }

  const requestCallback = (id: string | number) => {
    show(showPath(id), 'remindMail', (response: RemindMail) => {
      setFormData({
        title: response.title,
        body: response.body,
        // htmlBody: response.htmlBody,
        campaignId: campaignId,
        sendTimings: response.sendTimings,
      })
    })
    setErrors({});
  }

  const onCheck = (value: number) => {
    const sendTimings = { ...formData }["sendTimings"]
    sendTimings.push(value);
    setFormData({ ...formData, sendTimings });
  }

  const onUnCheck = (value: number) => {
    const sendTimings = { ...formData }["sendTimings"].filter(timing => !(timing == value))
    setFormData({ ...formData, sendTimings });
  }

  const onCreate = () => {
    const callback = (response: Response) => {
      requestCallback(response.id)
      setIsUpdate(true)
      setRemindMailId(response.id)
      alert('リマインドメールの作成に成功しました。');
    }

    const errCallback = (err: Errors) => {
      setErrors(err);
    }

    create(createPath, { remindMail: formData }, 'remindMail', callback, errCallback);
  }

  const onUpdate = () => {
    const callback = (response: Response) => {
      requestCallback(response.id);
      alert('リマインドメールの更新に成功しました。');
    }

    const errCallback = (err: Errors) => {
      setErrors(err);
    }

    if (remindMailId) {
      update(updatePath(remindMailId), { remindMail: formData }, 'remindMail', callback, errCallback);
    }
  }

  return (
    <Form noValidate>
      <Form.Group controlId='remindMailTitle'>
        <Form.Label>メールタイトル</Form.Label>
        <Form.Control
          type='text'
          name="title"
          defaultValue={ formData.title }
          onChange={ e => onChange(e.target.value, 'title') }
          isInvalid={ errors.title && errors.title?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.title?.map((err, i) => <p key={ `error-name-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='remindMailBody'>
        <Form.Label>本文(textメール)</Form.Label>
        <Form.Control
          as="textarea"
          name="body"
          defaultValue={ formData.body }
          onChange={ e => onChange(e.target.value, 'body') }
          isInvalid={ errors.body && errors.body?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.body?.map((err, i) => <p key={ `error-body-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      {/* <Form.Group controlId='remindMailHtmlBody'>
        <Form.Label>本文(htmlメール)</Form.Label>
        <Form.Control
          as="textarea"
          name="htmlBody"
          defaultValue={ formData.htmlBody }
          onChange={ e => onChange(e.target.value, 'htmlBody') }
          isInvalid={ errors.htmlBody?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.htmlBody?.map((err, i) => <p key={ `error-htmlBody-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group> */}

      <Form.Group controlId='remindMailSendTiming'>
        <Form.Label>送信日</Form.Label>
        <div className={ errors.sendTimings && (errors.sendTimings?.length > 0) ? 'is-invalid' : ''}>
          {
            [14, 13, 12, 11, 10 ,9 ,7, 5, 3, 1].map(n =>
              <Form.Check
                key={ `check-key-${n}` }
                inline
                type={ 'checkbox' }
                id={ `sendTiming-${n}` }
                name='sendTiming'
                label={ `${n}日前` }
                value={ n }
                defaultChecked={ formData['sendTimings'].includes(n) }
                onChange={ e => e.target.checked ? onCheck(Number(e.target.value)) : onUnCheck(Number(e.target.value)) }
              />
            )
          }
        </div>
        <Form.Control.Feedback type="invalid">
          { errors.sendTimings?.map((err, i) => <p key={ `error-name-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>
      <Button type="button" variant='outline-success' onClick={ isUpdate ? onUpdate : onCreate }>
        { isUpdate ? '更新' : '作成' }
      </Button>
    </Form>
  )
}

export default RemindMailForm;