import { get } from 'libs/fetch';

export const indexPath = 'api/v1/schools';

export type School = {
  id: number;
  name: string;
  degree: string;
  faculty: string;
  department: string;
  searchKey: string;
}

export const getSchools = async (callback: (responseData: School[]) => void) => {
  const res = await get('api/v1/schools');
  const body = await res.json();

  const responseData: School[] = body.schools;
  callback(responseData)
}