import {
  get as fetchGet,
  post,
  postFormData,
  patch,
  patchFormData,
  destroy as fetchDestroy
} from 'libs/fetch';

// TODO: keyは微妙なのでapi側でフォーマットを統一する

export const get = async function <ResponseData>(
  path: string, key: string,
  callback: (responseData: ResponseData) => void,
  forbiddenCallback?: ForbiddenCallback,
) {
  const res = await fetchGet(path);
  const body = await res.json();

  if (res.status === 200) {
    const responseData: ResponseData = body[key];
    callback(responseData)
  } else if (res.status == 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
}

// deplicated
export const show = async function <ResponseData>(
  path: string, key: string,
  callback: (responseData: ResponseData) => void,
  forbiddenCallback?: () => void,
) {
  const res = await fetchGet(path);
  const body = await res.json();

  if (res.status === 200) {
    const responseData: ResponseData = body[key];
    callback(responseData)
  } else if (res.status == 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
}

type Callback<T> = (response: T) => void;
type errCallback<T> = (err: T) => void;
type ForbiddenCallback = () => void;

export const create = async function <Data extends object, Response, Errors>(
  path: string,
  data: Data,
  key: string,
  callback: Callback<Response>,
  errCallback: errCallback<Errors>
) {
  const res = await post(path, data);
  const body = await res.json();

  if (res.status == 200) {
    const responseData: Response = body[key];
    callback(responseData)
  } else if (res.status == 400) {
    const errors: Errors = body.errors;
    errCallback(errors);
  }
}

export { create as post };

export const createFormData = async function <Data extends FormData, Response, Errors>(
  path: string,
  data: Data,
  key: string,
  callback: Callback<Response>,
  errCallback: errCallback<Errors>,
  forbiddenCallback?: ForbiddenCallback,
) {
  const res = await postFormData(path, data);
  const body = await res.json();

  if (res.status == 200) {
    const responseData: Response = body[key];
    callback(responseData)
  } else if (res.status == 400) {
    const errors: Errors = body.errors;
    errCallback(errors);
  } else if (res.status === 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
}

export const update = async function <Data extends object, Response, Errors>(
  path: string,
  data: Data,
  key: string,
  callback: Callback<Response>,
  errCallback: errCallback<Errors>,
  forbiddenCallback?: ForbiddenCallback,
) {
  const res = await patch(path, data);
  const body = await res.json();

  if (res.status == 200) {
    const responseData: Response = body[key];
    callback(responseData)
  } else if (res.status == 400) {
    const errors: Errors = body.errors;
    errCallback(errors);
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }
}

export { update as patch }

export const updateFormData = async function <Data extends FormData, Response, Errors>(
  path: string,
  data: Data,
  key: string,
  callback: Callback<Response>,
  errCallback: errCallback<Errors>,
  forbiddenCallback: ForbiddenCallback,
) {
  const res = await patchFormData(path, data);
  const body = await res.json();

  if (res.status == 200) {
    const responseData: Response = body[key];
    callback(responseData)
  } else if (res.status == 400) {
    const errors: Errors = body.errors;
    errCallback(errors);
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }
}

export const destroy = async function <Response, Errors>(
  path: string,
  key: string,
  callback: Callback<Response>,
  errCallback: errCallback<Errors>
) {
  const res = await fetchDestroy(path);
  const body = await res.json();

  if (res.status == 200) {
    const responseData: Response = body[key];
    callback(responseData)
  } else if (res.status == 400) {
    const errors: Errors = body.errors;
    errCallback(errors);
  }
}