import { FC, useEffect, useState } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import Table from 'components/Table';
import PageTitle from 'components/PageTitle';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { newCompaniesPath, showCompaniesPathCreator } from 'routes/companies/path';
import { index, Company } from 'libs/apiClient/admin/companies';
import Forbidden from 'pages/forbidden';

const Index: FC = () => {
  const history = useHistory();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  useEffect(() => {
    const forbiddenCallback = () => { setIsForbidden(true); }
    index(setCompanies, forbiddenCallback)
  }, []);

  const Page = () => {

    return (
      <Layout>
        <PageTitle title="企業一覧">
          <Button onClick={() => history.push(newCompaniesPath)}>新規追加</Button>
        </PageTitle>

        <Panel>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>企業名</th>
                <th>クライアント</th>
                <th>キャンペーン数</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {
                companies.map(company => {
                  return (
                    <tr key={company.id}>
                      <td className="align-middle" >{company.id}</td>
                      <td className="align-middle" >{company.name}</td>
                      <td className="align-middle" >
                        {company.clients.map((client, key) => <p key={`client-email-${key}`} className="mb-0">{client.email}</p>)}
                      </td>
                      <td className="align-middle" >{company.campaigns || 0}</td>
                      <td className="align-middle" >
                        <Button variant='dark' onClick={() => history.push(`/companies/${company.id}/edit`)}>編集</Button>
                        <Button variant="outline-info" className="ml-3" onClick={() => history.push(showCompaniesPathCreator(company.id))}>詳細</Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Index;