import { FC, Dispatch, useState, SetStateAction } from 'react';
import {
  TextFieldType,
  PasswordType,
  TextareaType,
  MultipleItemType,
  MultipleItem,
  RequestData,
  Errors
} from 'libs/apiClient/admin/customFormItems';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import { nanoid } from 'nanoid';
import { Select } from './Select';
import { FieldContext, SelectDetail } from 'types/api/admin/customFormItems'

type Props = {
  formData: RequestData;
  setFormData: Dispatch<SetStateAction<RequestData>>;
  onSubmit: () => void;
  errors: Errors;
  setErrors: Dispatch<SetStateAction<Errors>>;
  customFormItemId: number | string | null;
}

const CustomFormItemForm: FC<Props> = (props) => {
  const { formData, setFormData, onSubmit, errors, setErrors, customFormItemId } = props;

  const onChangeItemDetail = <V,>(value: V) => {
    const newFormData = { ...formData, itemDetail: value }
    setFormData(newFormData)
  }

  const changeForm = (fieldContext: FieldContext) => {
    switch (fieldContext) {
      case 'text_field':
        return <TextField formData={ formData } setFormData={ setFormData } errors={ errors } />
      case 'textarea':
        return <TextArea key={ `textarea-${customFormItemId}` } formData={ formData } setFormData={ setFormData } />
      case 'radio':
        return <MultipleInput key={ `radio-${customFormItemId}` } formData={ formData } setFormData={ setFormData } errors={ errors } />
      case 'checkbox':
        return <MultipleInput key={ `checkbox-${customFormItemId}` } formData={ formData } setFormData={ setFormData } errors={ errors } />
      case 'selectbox':
        const defaultSelectDetail: SelectDetail = { placeholder: '', items: [] }
        const selectDetail = Object.keys(formData.itemDetail).length > 0 ? formData.itemDetail as SelectDetail : defaultSelectDetail
        return (
          <Select
            selectDetail={selectDetail}
            onChange={ data => onChangeItemDetail(data) }
            errors={[]}
          />
        )
      case 'agreement':
        return <Agreement key={ `agreement-${customFormItemId}` } formData={ formData } setFormData={ setFormData } errors={ errors } />
      case 'password':
        return <Password key={ `password-${customFormItemId}` } formData={ formData } setFormData={ setFormData } errors={ errors } />
      case 'datetime_field' || 'full_address':
        return <p>追加で入力が必要な項目はありません。</p>
    }
  }

  const onFormDataChange = (value: RequestData[keyof RequestData], name: keyof RequestData) => {
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData)
  }

  const onChangeFieldContext = (value: FieldContext) => {
    const newFormData = {
      ...formData,
      'fieldContext': value,
      'itemDetail': {}
    }

    setFormData(newFormData)
    setErrors({})
  }

  return (
    <Panel>
      <PanelTitle title={ customFormItemId ? `カスタムフォームアイテム編集(ID${customFormItemId})` : 'カスタムフォームアイテム新規作成' } />
      <Form className='col-6'>
        <Form.Group controlId='customFormItemfieldContext'>
          <Form.Label>タイプ</Form.Label>
          <Form.Control
            as="select" custom
            onChange={ e => onChangeFieldContext(e.target.value as FieldContext) }
            value={ formData.fieldContext }
            isInvalid={ errors.fieldContext && errors.fieldContext?.length > 0 }
          >
            <option value='text_field'>テキスト</option>
            <option value='textarea'>テキストエリア</option>
            <option value='radio'>ラジオ</option>
            <option value='checkbox'>チェックボックス</option>
            <option value='selectbox'>セレクト</option>
            <option value='agreement'>利用規約/プライバシーポリシー</option>
            <option value='datetime_field'>日付</option>
            <option value='full_address'>住所</option>
            <option value='password'>パスワード</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            { errors.fieldContext?.map((err, i) => <p key={ `error-type-${i}` }>{ err }</p>) }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='customFormItemIsRequired'>
          <Form.Label>必須/任意</Form.Label>
          <div>
            <Form.Check
              inline
              type={ 'radio' }
              id='isRequiredTrue'
              name='isRequired'
              label='必須'
              checked={ formData.isRequired }
              value='true'
              onChange={ _e => onFormDataChange(true, 'isRequired') }
            />
            <Form.Check
              inline
              type={ 'radio' }
              id='isRequiredFalse'
              name='isRequired'
              label='任意'
              checked={ !formData.isRequired }
              value='false'
              onChange={ _e => onFormDataChange(false, 'isRequired') }
            />
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label>表示順</Form.Label>
          <Form.Control
            type='number'
            name="priority"
            value={ formData.priority }
            onChange={ e => onFormDataChange(e.target.value, 'priority') }
            isInvalid={ errors.priority && errors.priority?.length > 0 }
          />
          <Form.Control.Feedback type="invalid">
            { errors.priority?.map((err, i) => <p key={ `error-priority-${i}` }>{ err }</p>) }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='customFormItemLabel'>
          <Form.Label>ラベル</Form.Label>
          <Form.Control
            type='text'
            name="label"
            value={ formData.label }
            onChange={ e => onFormDataChange(e.target.value, 'label') }
            isInvalid={ errors.label && errors.label?.length > 0 }
          />
          <Form.Control.Feedback type="invalid">
            { errors.label?.map((err, i) => <p key={ `error-label-${i}` }>{ err }</p>) }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label className='mt-3 mb-1'>追加項目</Form.Label>
          <div className={ errors.itemDetail && errors.itemDetail.length > 0 ? 'is-invalid' : '' }></div>
          <Form.Control.Feedback type="invalid">
            { errors.itemDetail?.map((err, i) => <span className='d-block mb-1' key={ `error-itemDetail-${i}` }>{ err }</span>) }
          </Form.Control.Feedback>
          { changeForm(formData.fieldContext) }
        </Form.Group>

        <Form.Group>
          <Button type="button" variant='outline-success' onClick={ onSubmit }>登録</Button>
        </Form.Group>
      </Form>
    </Panel>
  )
}

type TextFieldProps = {
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
}

const TextField: FC<TextFieldProps> = (props) => {
  const { formData, setFormData, errors } = props;
  const [itemDetail, setItemDetail] = useState<TextFieldType>(formData.itemDetail as TextFieldType);

  const onChange = (value: number | string, name: keyof TextFieldType) => {
    const newItemDetail: TextFieldType = { ...itemDetail, [name]: value };
    setItemDetail(newItemDetail);

    const newFormData = { ...formData };
    newFormData['itemDetail'] = newItemDetail;
    setFormData(newFormData);
  }

  return (
    <>
      <Form.Group>
        <Form.Label>タイプ</Form.Label>
        <Form.Control
          as="select" custom
          onChange={ e => onChange(e.target.value, 'type') }
          value={ itemDetail.type }
          isInvalid={ errors.itemDetailTextFieldType && errors.itemDetailTextFieldType.length > 0 }
        >
          <option></option>
          <option value='text'>テキスト</option>
          <option value='number'>数字</option>
          <option value='email'>email</option>
          <option value='telephone'>電話番号</option>
          <option value='url'>url</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          { errors.itemDetailTextFieldType?.map((err, i) => <span className='d-block mb-1' key={ `error-itemDetailTextFieldTyspane-${i}` }>{ err }</span>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Row>
        <Form.Group as={Col} md="6">
          <Form.Label>最小文字数</Form.Label>
          <Form.Control
            type='number'
            name="minlength"
            value={ itemDetail?.minlength }
            onChange={ e => onChange(e.target.value, 'minlength') }
          />
        </Form.Group>

        <Form.Group as={Col} md="6">
          <Form.Label>最大文字数</Form.Label>
          <Form.Control
            type='number'
            name="maxlength"
            value={ itemDetail?.maxlength }
            onChange={ e => onChange(e.target.value, 'maxlength') }
          />
        </Form.Group>
      </Row>

      <Form.Group>
        <Form.Label>プレースホルダー</Form.Label>
        <Form.Control
          type='text'
          name="placeholder"
          value={ itemDetail?.placeholder }
          onChange={ e => onChange(e.target.value, 'placeholder') }
        />
      </Form.Group>
    </>
  )
}

type TextAreaProps = {
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
}

const TextArea: FC<TextAreaProps> = (props) => {
  const { formData, setFormData } = props;
  const [itemDetail, setItemDetail] = useState<TextareaType>(formData.itemDetail as TextareaType);

  const onChange = (value: string | number, name: keyof TextareaType) => {
    const newItemDetail = { ...itemDetail, [name]: value };
    setItemDetail(newItemDetail);

    const newFormData = { ...formData };
    newFormData['itemDetail'] = newItemDetail;
    setFormData(newFormData);
  }

  return (
    <>
      <Row>
        <Form.Group as={Col} md="6">
          <Form.Label>最小文字数</Form.Label>
          <Form.Control
            type='number'
            name="minlength"
            value={ itemDetail?.minlength || '' }
            onChange={ e => onChange(e.target.value, 'minlength') }
          />
        </Form.Group>

        <Form.Group as={Col} md="6">
          <Form.Label>最大文字数</Form.Label>
          <Form.Control
            type='number'
            name="maxlength"
            value={ itemDetail?.maxlength || '' }
            onChange={ e => onChange(e.target.value, 'maxlength') }
          />
        </Form.Group>
      </Row>

      <Form.Group>
        <Form.Label>プレースホルダー</Form.Label>
        <Form.Control
          type='text'
          name="placeholder"
          value={ itemDetail?.placeholder || '' }
          onChange={ e => onChange(e.target.value, 'placeholder') }
        />
      </Form.Group>
    </>
  )
}

type MultipleInputItem = {
  index: number;
  label: string;
  value: string;
  isDefault: boolean;
}

type MultipleInputProps = {
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
}

const MultipleInput: FC<MultipleInputProps> = (props) => {
  const { formData, setFormData, errors } = props;
  const itemDetail = formData.itemDetail as MultipleItemType;
  const itemTemplate = (index: number): MultipleInputItem => {
    return { index, label: '', value: '', isDefault: false }
  };
  const defaultItems = () => {
    if (itemDetail.items && itemDetail.items.length > 0) {
      return itemDetail.items.map((item: MultipleItem, index: number) => {
        return {
          index,
          label: item.label,
          value: item.value,
          isDefault: item.isDefault
        }
      });
    }

    return [itemTemplate(0)];
  }

  const [items, setItems] = useState<MultipleInputItem[]>(defaultItems());

  const onChangeFormData = (newItems: MultipleInputItem[]): void => {
    const multiItems = newItems.map(item => {
      const { index, ...res } = item;
      return res;
    });
    console.log(multiItems);
    const newFormData = { ...formData }
    newFormData.itemDetail = { items: multiItems };
    setFormData(newFormData);
  }

  const onAdd = () => {
    const newItems = [...items, itemTemplate(items.length)];
    setItems(newItems);
    onChangeFormData(newItems);
  }

  const onRemove = (index: number) => {
    const newItems = items.filter((item: MultipleInputItem) => item.index != index);
    setItems(newItems);
    onChangeFormData(newItems);

  }

  const onItemChange = (value: string | boolean, name: 'label' | 'value' | 'isDefault', index: number) => {
    console.log(value);
    const newItems: MultipleInputItem[] = [...items];

    // defaultは常に一つ
    if (name == 'isDefault' && value) {
      newItems.forEach(item => item['isDefault'] = false);
    }

    newItems[index] = { ...newItems[index], [name]: value }
    setItems(newItems);
    onChangeFormData(newItems);
  }

  return (
    <>
      <div className={ errors.itemDetailSelectboxItems && errors.itemDetailSelectboxItems.length > 0 ? 'is-invalid' : '' }></div>
      <Form.Control.Feedback type="invalid">
        { errors.itemDetailSelectboxItems?.map((err, i) => <span className='d-block mb-1' key={ `error-itemDetailSelectboxItems-${i}` }>{ err }</span>) }
      </Form.Control.Feedback>

      <div className={ errors.itemDetailRadioItems && errors.itemDetailRadioItems.length > 0 ? 'is-invalid' : '' }></div>
      <Form.Control.Feedback type="invalid">
        { errors.itemDetailRadioItems?.map((err, i) => <span className='d-block mb-1' key={ `error-itemDetailRadioItems-${i}` }>{ err }</span>) }
      </Form.Control.Feedback>

      <div className={ errors.itemDetailCheckboxItems && errors.itemDetailCheckboxItems.length > 0 ? 'is-invalid' : '' }></div>
      <Form.Control.Feedback type="invalid">
        { errors.itemDetailCheckboxItems?.map((err, i) => <span className='d-block mb-1' key={ `error-itemDetailCheckboxItems-${i}` }>{ err }</span>) }
      </Form.Control.Feedback>

      { items.map((item: MultipleInputItem, i: number) => {
        return (
          <div className={ `mb-3` } key={`mul-key-${i}`}>
            <Row>
              <Form.Group as={Col} md="3">
                <Form.Control
                  key={ `custom-form-label` }
                  type='text'
                  name="label"
                  value={ item.label }
                  placeholder='ラベル'
                  onChange={ e => onItemChange(e.target.value, 'label', i) }
                />
              </Form.Group>

              <Form.Group as={Col} md="3">
                <Form.Control
                  type='text'
                  name="value"
                  value={ item.value }
                  placeholder='値'
                  onChange={ e => onItemChange(e.target.value, 'value', i) }
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="align-self-center" controlId={ `multiple-default-${nanoid(8)}` }>
                <Form.Check
                  type={ 'checkbox' }
                  name='isDefault'
                  checked={ item.isDefault }
                  label={ 'デフォルト' }
                  onChange={ e => onItemChange(e.target.checked, 'isDefault', i) }
                />
              </Form.Group>
                { i > 0 && (
                  <Form.Group as={Col} md="3" className="align-self-center" controlId='multiple-delete'>
                    <Button variant="light" onClick={ _e => onRemove(item.index)}>削除</Button>
                  </Form.Group>
                )}
            </Row>
          </div>
        )
      })}

      <Button variant="secondary" onClick={ _e => onAdd() }>選択肢を追加</Button>
    </>
  )
}

type AgreementItem = {
  label: string;
  value: string;
  isDefault: boolean;
}

type AgreementProps = {
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
}

const Agreement: FC<AgreementProps> = (props) => {
  const { formData, setFormData, errors } = props;
  const [itemDetail, setItemDetail] = useState<AgreementItem>(formData.itemDetail as AgreementItem);

  const onChange = (value: string | boolean, name: keyof AgreementItem) => {
    const newItemDetail: AgreementItem = { ...itemDetail, [name]: value };
    setItemDetail(newItemDetail);

    const newFormData = { ...formData };
    newFormData['itemDetail'] = newItemDetail;
    setFormData(newFormData);
  }

  return (
    <>
      <Form.Group>
        <Form.Control
          key={ `custom-form-label` }
          as="textarea"
          name="label"
          value={ itemDetail.label }
          placeholder='Ann株式会社の「<a href="https://shukatsu-times.jp/privacy" rel="noopener noreferrer" target="_blank">プライバシーポリシー</a>」へ同意する'
          isInvalid={ errors.itemDetailAgreementLabel && errors.itemDetailAgreementLabel?.length > 0 }
          onChange={ e => onChange(e.target.value, 'label') }
        />
        <Form.Control.Feedback type="invalid">
          { errors.itemDetailAgreementLabel?.map((err, i) => <p key={ `error-itemDetailAgreementLabel-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="3" className="align-self-center" controlId={ `multiple-default-${nanoid(8)}` }>
        <Form.Check
          type={ 'checkbox' }
          name='isDefault'
          checked={ itemDetail.isDefault }
          label={ 'デフォルト' }
          onChange={ e => onChange(e.target.checked, 'isDefault') }
        />
      </Form.Group>
    </>
  )
}

type PasswordProps = {
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
}

const Password: FC<PasswordProps> = (props) => {
  const { formData, setFormData, errors } = props;
  const [itemDetail, setItemDetail] = useState<PasswordType>(formData.itemDetail as PasswordType);

  const onChange = (value: number | string, name: keyof PasswordType) => {
    const newItemDetail: PasswordType = { ...itemDetail, [name]: value };
    setItemDetail(newItemDetail);

    const newFormData = { ...formData };
    newFormData['itemDetail'] = newItemDetail;
    setFormData(newFormData);
  }

  return (
    <>
      <Row>
        <Form.Group as={Col} md="6">
          <Form.Label>最小文字数</Form.Label>
          <Form.Control
            type='number'
            name="minlength"
            value={ itemDetail?.minlength }
            onChange={ e => onChange(e.target.value, 'minlength') }
          />
        </Form.Group>

        <Form.Group as={Col} md="6">
          <Form.Label>最大文字数</Form.Label>
          <Form.Control
            type='number'
            name="maxlength"
            value={ itemDetail?.maxlength }
            onChange={ e => onChange(e.target.value, 'maxlength') }
          />
        </Form.Group>
      </Row>

      <Form.Group>
        <Form.Label>パスワードバリデーション</Form.Label>
        <Form.Control
          type='text'
          name="regularExpression"
          value={ itemDetail?.regularExpression }
          onChange={ e => onChange(e.target.value, 'regularExpression') }
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>パスワードバリデーションのエラーメッセージ</Form.Label>
        <Form.Control
          type='text'
          name="regularExpressionErrorMessage"
          value={ itemDetail?.regularExpressionErrorMessage }
          onChange={ e => onChange(e.target.value, 'regularExpressionErrorMessage') }
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>プレースホルダー</Form.Label>
        <Form.Control
          type='text'
          name="placeholder"
          value={ itemDetail?.placeholder }
          onChange={ e => onChange(e.target.value, 'placeholder') }
        />
      </Form.Group>
    </>
  )
}


export default CustomFormItemForm;