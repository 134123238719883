import { FC, useState } from 'react';
import { Button } from 'react-bootstrap';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import {useDropzone} from 'react-dropzone';
import styles from './csvImportForm.module.scss';
import { useBulkInsert } from 'hooks/api/admin/schedules/useBulkInsert';

interface FormProps {
  title: string;
  campaignId: string | number;
}

interface Errors {
  [key: string]: string[]
}

export const CsvImportForm: FC<FormProps> = (props) => {
  const {
    title,
    campaignId
  } = props;

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({});
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({ accept: 'text/csv' });
  const post = useBulkInsert

  const files = () => {
    if (acceptedFiles.length > 0) {
      return acceptedFiles.map((file) =>
        <li key={file.name}>
          {file.name}
        </li>
      )
    }

    return <li>ファイルが選択されていません</li>
  };

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append('schedule[csv_file]', acceptedFiles[0])
    formData.append('schedule[campaign_id]', campaignId as string)
    const res = await post(formData)

    if (!res.result) {
      if (res.status == 400) {
        setErrors(res.errors)
      } else {
        setErrors({ 'internalServerError': ['エラーが発生しました。csvの内容を確認してください。']})
      }
    } else {
      setIsSuccess(true)
    }
  }

  return (
    <Panel>
      <PanelTitle title={ title }/>
      <div className='mb-3'>
        <a className='btn btn-outline-primary mr-2' href='/file/offline_schedule.csv' download>オフラインイベント用csvサンプル</a>
        <a className='btn btn-outline-primary' href='/file/online_schedule.csv' download>オンラインイベント用csvサンプル</a>
      </div>

      { isSuccess ? (
        <p>スケジュールを登録しています。リロードボタンで反映を確認できます。</p>
      ) : (
        <section>
          <div {...getRootProps({className: `${styles.dropzone} p-2 mb-3`})}>
            <input {...getInputProps()} />
            <span>CSVファイルをドラッグ＆ドロップしてください</span>
          </div>
          <aside>
            { Object.keys(errors).map((key: string) => {
              return (
                <ul key={`${key}-ul`}>
                  { errors[key].map((message: string, i: number) => <li key={`${key}-msg-${i}`}>{message}</li>) }
                </ul>
              )
            })}
            <p>選択中</p>
            <ul>{files()}</ul>
          </aside>
          <Button type="button" variant='outline-success' onClick={ onSubmit }>保存</Button>
        </section>
      )}
    </Panel>
  )
}
