import { FC, useEffect, useState } from "react";
import Layout from "layouts/Layout";
import PageTitle from "components/PageTitle";
import Panel from "components/Panel";
import PanelTitle from "components/PanelTitle";
import Table from "components/Table";
import { Button, Row, Breadcrumb } from "react-bootstrap";
import { RouteComponentProps, useHistory } from "react-router";
import { show, Company } from "libs/apiClient/admin/companies";
import { index, Campaign } from "libs/apiClient/admin/campaigns";
import {
  showCompanyCampaignPathCreator,
  editCompanyCampaignPathCreator,
} from "routes/companies/campaigns/path";
import Forbidden from "pages/forbidden";

type urlProps = {} & RouteComponentProps<{ id: string }>;

const Show: FC<urlProps> = (props: urlProps) => {
  const history = useHistory();
  const [company, setCompany] = useState<Company>();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);
  const companyId = props.match.params.id;

  useEffect(() => {
    const forbiddenCallback = () => {
      setIsForbidden(true);
    };

    show(companyId, setCompany, forbiddenCallback);
    index(companyId, setCampaigns, forbiddenCallback);
  }, []);

  const Page = () => {
    return (
      <Layout>
        <Breadcrumb>
          <Breadcrumb.Item href="/companies">企業一覧</Breadcrumb.Item>
          <Breadcrumb.Item active>{company?.name}</Breadcrumb.Item>
        </Breadcrumb>
        <PageTitle title="企業詳細" />
        <Row className="d-flex">
          <Panel className="col-6 pb-2">
            <PanelTitle title="基本情報" />
            <p>id: {company?.id}</p>
            <p>企業名: {company?.name}</p>
          </Panel>
          <Panel className="col-6 pl-1 pb-2">
            <PanelTitle title="クライアントアカウント">
              <Button
                variant="primary"
                onClick={() => history.push(`/clients/:id`)}
              >
                新規作成
              </Button>
            </PanelTitle>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>email</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                {company?.clients?.map((client) => {
                  return (
                    <tr key={client.id}>
                      <td className="align-middle">{client.id}</td>
                      <td className="align-middle">{client.email}</td>
                      <td className="align-middle">
                        <Button
                          variant="dark"
                          onClick={() =>
                            history.push(`/companies/${company?.id}/edit`)
                          }
                        >
                          編集
                        </Button>
                        <Button
                          variant="outline-info"
                          className="ml-3"
                          onClick={() => history.push(`/clients/:id`)}
                        >
                          詳細
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Panel>
        </Row>
        <Panel>
          <PanelTitle title="キャンペーン一覧">
            <Button
              variant="primary"
              onClick={() =>
                history.push(`/companies/${company?.id}/campaigns/new`)
              }
            >
              新規作成
            </Button>
          </PanelTitle>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>卒年</th>
                <th>キャンペーン名</th>
                <th>キャンペーンタイプ</th>
                <th>メモ</th>
                <th>ステータス</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {campaigns.map((campaign) => {
                return (
                  <tr key={campaign.id}>
                    <td className="align-middle">{campaign.id}</td>
                    <td className="align-middle">
                      {campaign.graduationYears.map((graduationYear) => {
                        return (
                          <>
                            {graduationYear.year} 年
                            <br />
                          </>
                        );
                      })}
                    </td>
                    <td className="align-middle">
                      {campaign.campaignable.name}
                    </td>
                    <td className="align-middle">{campaign.type}</td>
                    <td className="align-middle">
                      {campaign.campaignable.memo}
                    </td>
                    <td className="align-middle">{campaign.status}</td>
                    <td className="align-middle">
                      <Button
                        variant="dark"
                        onClick={() =>
                          history.push(
                            editCompanyCampaignPathCreator(
                              companyId,
                              campaign.id
                            )
                          )
                        }
                      >
                        編集
                      </Button>
                      <Button
                        variant="outline-info"
                        className="ml-3"
                        onClick={() =>
                          history.push(
                            showCompanyCampaignPathCreator(
                              companyId,
                              campaign.id
                            )
                          )
                        }
                      >
                        詳細
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Panel>
      </Layout>
    );
  };

  if (isForbidden) {
    return <Forbidden />;
  } else {
    return Page();
  }
};

export default Show;
