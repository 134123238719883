import { FC, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { showPath, createPath, updatePath, EntrySpreadsheet, RequestData, Response, Errors } from 'libs/apiClient/admin/entrySpreadsheets';
import { show, create, update } from 'libs/apiClient';

type Props = {
  entrySpreadsheet: EntrySpreadsheet | undefined;
  campaignId: string | number;
}

const EntrySpreadsheetForm: FC<Props> = (props) => {
  const { entrySpreadsheet, campaignId } = props;
  const [formData, setFormData] = useState<RequestData>({
    isSendingSpreadsheet: entrySpreadsheet?.isSendingSpreadsheet || false,
    spreadsheetId: entrySpreadsheet?.spreadsheetId || '',
    spreadsheetWorksheetName: entrySpreadsheet?.spreadsheetWorksheetName || '',
    campaignId: campaignId
  });
  const [errors, setErrors] = useState<Errors>({});
  const [isUpdate, setIsUpdate] = useState<boolean>(Boolean(entrySpreadsheet))
  const [entrySpreadsheetId, setEntrySpreadsheetId] = useState<string|number|undefined>(entrySpreadsheet?.id)

  const onChange = (value: RequestData[keyof RequestData], key: keyof RequestData): void => {
    const data = { ...formData, [key]: value }
    setFormData(data);
  }

  const requestCallback = (id: string | number) => {
    show(showPath(id), 'entrySpreadsheet', (response: EntrySpreadsheet) => {
      setFormData({
        isSendingSpreadsheet: response.isSendingSpreadsheet,
        spreadsheetId: response.spreadsheetId,
        spreadsheetWorksheetName: response.spreadsheetWorksheetName,
        campaignId: campaignId,
      })
    })
    setErrors({})
  }

  const onCreate = () => {
    const callback = (response: Response) => {
      requestCallback(response.id)
      setIsUpdate(true)
      setEntrySpreadsheetId(response.id)
      alert('エントリースプレッドシートの作成に成功しました。');
    }

    const errCallback = (err: Errors) => {
      setErrors(err);
    }

    create(createPath, { entrySpreadsheet: formData }, 'entrySpreadsheet', callback, errCallback);
  }

  const onUpdate = () => {
    const callback = (response: Response) => {
      requestCallback(response.id)
      alert('エントリースプレッドシートの更新に成功しました。');
    }

    const errCallback = (err: Errors) => {
      setErrors(err);
    }

    if (entrySpreadsheetId) {
      update(updatePath(entrySpreadsheetId), { entrySpreadsheet: formData }, 'entrySpreadsheet', callback, errCallback);
    }
  }

  return (
    <Form noValidate>
      <Form.Group controlId='isSendingSpreadsheet'>
        <Form.Check
          key='check-key-isSendingSpreadsheet'
          inline
          type={ 'checkbox' }
          id='isSendingSpreadsheet'
          name='isSendingSpreadsheet'
          label={ `スプレッドシートにデータを書き出す` }
          checked={ formData.isSendingSpreadsheet }
          onChange={ e => e.target.checked ? onChange(true, 'isSendingSpreadsheet') : onChange(false, 'isSendingSpreadsheet') }
        />
        <Form.Control.Feedback type="invalid">
          { errors.isSendingSpreadsheet?.map((err, i) => <p key={ `error-name-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='spreadsheetId'>
        <Form.Label>スプレッドシートID</Form.Label>
        <Form.Control
          type='text'
          name="spreadsheetId"
          defaultValue={ formData.spreadsheetId }
          onChange={ e => onChange(e.target.value, 'spreadsheetId') }
          isInvalid={ errors.spreadsheetId && errors.spreadsheetId?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.spreadsheetId?.map((err, i) => <p key={ `error-body-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='spreadsheetWorksheetName'>
        <Form.Label>ワークシート名</Form.Label>
        <Form.Control
          type='text'
          name="spreadsheetWorksheetName"
          defaultValue={ formData.spreadsheetWorksheetName }
          onChange={ e => onChange(e.target.value, 'spreadsheetWorksheetName') }
          isInvalid={ errors.spreadsheetWorksheetName && errors.spreadsheetWorksheetName?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.spreadsheetWorksheetName?.map((err, i) => <p key={ `error-htmlBody-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Button type="button" variant='outline-success' onClick={ isUpdate ? onUpdate : onCreate }>
        { isUpdate ? '更新' : '作成' }
      </Button>
    </Form>
  )
}

export default EntrySpreadsheetForm;