import { FC, useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import {
  indexPath,
  Schedule,
} from 'libs/apiClient/admin/schedules';
import { 
  createPath,
  RequestData,
  Errors,
  Response
} from 'libs/apiClient/admin/entries';
import { get, create } from 'libs/apiClient';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import Table from 'components/Table';
import UserEntryForm from './UserEntryForm';
import { Prefecture, indexPath as PrefecturesPath } from 'libs/apiClient/prefectures';
import Toast from 'components/Toast';
import useSWR, { useSWRConfig } from 'swr'

type Props = {
  userId: number | string | null;
}

const Schedules: FC<Props> = (props)  => {
  const { userId } = props;
  const [errors, setErrors] = useState<Errors>({});
  const [searchKeyword, setSearchKeyword] = useState<string|number|null>('');
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [isDisplayForm, setIsDisplayForm] = useState<boolean>(false);
  const [scheduleId, setScheduleId] = useState<number|string|null>(null);
  const [entryFormId, setEntryFormId] = useState<number|string|null>(null);
  const formDataTemplate = {
    userId: null,
    scheduleId: null,
    entryRoute: '',
    customFormItemAnswers: []
  }
  const [formData, setFormData] = useState<RequestData>(formDataTemplate);
  const [prefectures, setPrefectures] = useState<Prefecture[]>([]);
  const [isDispayToast, setIsDispayToast] = useState(false);
  const [message, setMessage] = useState<string|null>(null);
  const { mutate } = useSWRConfig();
  const query = new URLSearchParams();
  query.append('user_id', String(userId));

  useEffect(() => {
    // スケジュール一覧取得
    get(indexPath('keyword', searchKeyword), 'schedules', setSchedules);

    // 都道府県マスターの取得
    get(PrefecturesPath, 'prefectures', setPrefectures);

    // カスタムフォームを開いていた場合、フォームを閉じる（formDataを初期化する）
    onClose();
  }, [userId]);
  
  const onSearch = () => {
    // スケジュール検索
    get(indexPath('keyword', searchKeyword), 'schedules', setSchedules);
  }

  const onCreate = (userId: number | string | null, scheduleId: number | string, entryFormId: number | string | null) => {
    setScheduleId(scheduleId);
    setEntryFormId(entryFormId);
    const data = { ...formDataTemplate, ['userId']: userId, ['scheduleId']: scheduleId }
    setFormData(data);
    setIsDisplayForm(true);
    setErrors({});
  }

  const onSubmit = () => {
    const callback = (_res: Response) => {
      setMessage('エントリーが完了しました');
      setIsDispayToast(true);
      setIsDisplayForm(false);
      setScheduleId(null);
      setEntryFormId(null);
      setFormData(formDataTemplate);
      setErrors({});
      mutate(`api/v1/admin/entries?${query.toString()}`)
    }

    const errCallback = (err: Errors) => {
      setErrors(err)
    }

    create(createPath, { entry: { ...formData } }, 'entry', callback, errCallback);
  }

  const onClose = () => {
    setScheduleId(null);
    setEntryFormId(null);
    setIsDisplayForm(false);
    setFormData(formDataTemplate);
  }

  return (
    <>
      <Panel className='pb-4'>
        <PanelTitle title={ `キャンペーン検索(ユーザーID: ${userId})` } />
        <Form className='col-6'>
          <Form.Group controlId='campaignSearch'>
            <Form.Label>キャンペーン名</Form.Label>
            <Form.Control
              type='text'
              name="campaignSearch"
              onChange={ e => setSearchKeyword(e.target.value) }
            />
          </Form.Group>
          <Button type="button" variant='outline-primary' onClick={ onSearch }>検索</Button>
        </Form>
        <Table className='mb-2'>
          <thead>
            <tr>
              <th>ID</th>
              <th>キャンペーン名</th>
              <th>メモ</th>
              <th>エリア</th>
              <th>会場</th>
              <th>日時</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            { schedules && (
              schedules.map(schedule => {
                return (                  
                  <tr key={schedule.id}>
                    <td className={ `align-middle` } >{schedule.id}</td>
                    <td className={ `align-middle` } >{schedule.campaignable.name}</td>
                    <td className={ `align-middle` } >{schedule.campaignable.memo}</td>
                    <td className={ `align-middle` } >{schedule.area?.name}</td>
                    <td className={ `align-middle` } >{schedule.address1} {schedule.address2}</td>
                    <td className={ `align-middle` } >{schedule.startAt} - {schedule.endAt}</td>
                    <td className={ `align-middle`} >
                      <Button
                        variant='outline-success'
                        onClick={() => onCreate(userId, schedule.id, schedule.entryFormId)}
                      >
                        登録
                      </Button>
                    </td>
                  </tr>
                )
              })
            )}
          </tbody>
        </Table>

        {
          isDisplayForm && (
            <>
              <UserEntryForm
                formData={ formData }
                setFormData={ setFormData }
                userId={userId}
                scheduleId={scheduleId}
                entryFormId={entryFormId}
                prefectures={ prefectures }
                errors={ errors }
                setErrors={ setErrors }
                onSubmit={ onSubmit }
                onClose={ onClose }
              />
            </>
          )
        }
      </Panel>

      { isDispayToast && (
        <Toast
          message={message}
          isDispayToast={isDispayToast}
          setIsDispayToast={setIsDispayToast}
        />
        )
      }
    </>
  )
}

export default Schedules;
