import { FC, useState, useEffect } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import PageTitle from 'components/PageTitle';
import { Item } from 'components/Selectize';
import { useHistory } from 'react-router';
import { showEducationalBackgroundGroupsPathCreator } from 'routes/educationalBackgroundGroups/path';
import { getSchools, School } from 'libs/apiClient/schools';
import {
  update,
  UpdateData,
  UpdateErrors,
  UpdateResponse,
  show,
  EducationalBackgroundGroup
} from 'libs/apiClient/admin/educationalBackgroundGroups';
import EducationalBackgroundGroupForm from '../components/EducationalBackgroundGroupForm';
import { RouteComponentProps } from 'react-router-dom';
import Forbidden from 'pages/forbidden';

type Schools = {
  [key: string]: number[];
} & Object;

type urlProps = {} & RouteComponentProps<{ id: string }>;

const Edit: FC<urlProps> = (props) => {
  const history = useHistory()
  const [updateData, setUpdateData] = useState<UpdateData>({ name: '', schoolIds: [] });
  const [errors, setErrors] = useState<UpdateErrors>({ name: [], schoolIds: [] });
  const [schools, setSchools] = useState<Item<number[]>[]>([])
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);
  const educationalBackgroundGroupId = props.match.params.id

  useEffect(() => {
    const getFormData = async () => {
      // TODO: ここめっちゃ汚いのでリファクタする
      let selectedSchools: Schools = {}
      await show(educationalBackgroundGroupId, (responseData: EducationalBackgroundGroup) => {
        responseData.schools.forEach((school: School) => {
          if (selectedSchools.hasOwnProperty(school.name)) {
            selectedSchools[school.name].push(school.id);
          } else {
            selectedSchools[school.name] = [school.id];
          }
        })

        const newUpdateData = {
          name: responseData.name,
          schoolIds: responseData.schools.map(school => school.id)
        }

        setUpdateData(newUpdateData)
      })

      await getSchools((responseData: School[]) => {
        const data: Schools = {};
        responseData.forEach((school: School) => {
          if (data.hasOwnProperty(school.name)) {
            data[school.name].push(school.id);
          } else {
            data[school.name] = [school.id];
          }
        })
        const selectedkeys = Object.keys(selectedSchools);
        const schoolList = Object.keys(data).map((key, i) => {
          return {
            id: i + 1,
            label: key,
            value: data[key],
            isDisplay: true,
            isSelected: selectedkeys.includes(key)
          }
        });

        setSchools(schoolList);
      });
    }

    getFormData();
  }, [])

  const onSubmit = async () => {
    const callback = (res: UpdateResponse) => {
      history.push(showEducationalBackgroundGroupsPathCreator(res.id))
    }

    const errCallback = (err: UpdateErrors) => {
      setErrors(err);
    }

    const forbiddenCallback = () => { setIsForbidden(true); }

    await update(educationalBackgroundGroupId, updateData, callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="学歴グループ編集" />
        <Panel className="col-6">
          <EducationalBackgroundGroupForm
            formData={updateData}
            setFormData={setUpdateData}
            errors={errors}
            schools={schools}
            setSchools={setSchools}
            onSubmit={onSubmit}
          />
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Edit;