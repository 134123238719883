import { FC, useState, useEffect } from 'react';
import Layout from 'layouts/Layout';
import PageTitle from 'components/PageTitle';
import Panel from 'components/Panel';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { showPath, updatePath, RequestData, Errors, Creative } from 'libs/apiClient/admin/creatives';
import { show, updateFormData } from 'libs/apiClient';
import { showCompanyCampaignPathCreator } from 'routes/companies/campaigns/path';
import CreativeForm from '../components/CreativeForm';
import ImagePicker from '../components/ImagePicker';
import { Row } from 'react-bootstrap';
import Forbidden from 'pages/forbidden';

type urlProps = {} & RouteComponentProps<{ companyId: string, campaignId: string, id: string }>;

const Edit: FC<urlProps> = (props) => {
  const history = useHistory()
  const [formData, setFormData] = useState<RequestData>({
    administrativeTitle: '',
    memo: '',
    thumbnail: null,
    title: '',
    abstruct: '',
    body: '',
    style: '',
    isListed: false,
    isPublished: false,
    campaignId: props.match.params.campaignId
  });
  const [errors, setErrors] = useState<Errors>({})
  const [thumbnail, setThumbnail] = useState<string | undefined>();
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);
  const id = props.match.params.id;

  const forbiddenCallback = () => { setIsForbidden(true); }

  useEffect(() => {
    show(showPath(id), 'creative', (responseData: Creative) => {
      const newFormData: RequestData = { ...responseData, thumbnail: null };
      setFormData(newFormData);
      setThumbnail(responseData.thumbnail.url);
    }, forbiddenCallback)
  }, []);


  const onSubmit = (requestData: FormData) => {
    const callback = (_res: Response) => {
      history.push(showCompanyCampaignPathCreator(props.match.params.companyId, props.match.params.campaignId))
    }

    const errCallback = (err: Errors) => {
      setErrors(err);
    }

    updateFormData(updatePath(id), requestData, 'creative', callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="キャンペーン編集" />
        <Row>
          <Panel className="col-6 p-2">
            <CreativeForm
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              onSubmitFormData={onSubmit}
              thumbnail={thumbnail}
              setThumbnail={setThumbnail}
            />
          </Panel>
          <Panel className="col-6 p-2 pl-0">
            <ImagePicker />
          </Panel>
        </Row>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default Edit;