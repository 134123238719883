import { FC, useState, useEffect } from 'react';
import Layout from 'layouts/Layout';
import Panel from 'components/Panel';
import PageTitle from 'components/PageTitle';
import { Item } from 'components/Selectize';
import { useHistory } from 'react-router';
import { showEducationalBackgroundGroupsPathCreator } from 'routes/educationalBackgroundGroups/path';
import { getSchools, School } from 'libs/apiClient/schools';
import { create, CreateData, CreateErrors, CreateResponse } from 'libs/apiClient/admin/educationalBackgroundGroups';
import EducationalBackgroundGroupForm from '../components/EducationalBackgroundGroupForm';
import Forbidden from 'pages/forbidden';

type Schools = {
  [key: string]: number[];
} & Object;

const New: FC = () => {
  const history = useHistory()
  const [createData, setCreateData] = useState<CreateData>({ name: '', schoolIds: [] });
  const [errors, setErrors] = useState<CreateErrors>({ name: [], schoolIds: [] });
  const [schools, setSchools] = useState<Item<number[]>[]>([])
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  useEffect(() => {
    const shape = (res: School[]) => {

      const data: Schools = {};

      res.forEach((school: School) => {
        if (data.hasOwnProperty(school.name)) {
          data[school.name].push(school.id);
        } else {
          data[school.name] = [school.id];
        }
      })

      const schoolList = Object.keys(data).map((key, i) => {
        return {
          id: i + 1,
          label: key,
          value: data[key],
          isDisplay: true,
          isSelected: false
        }
      });
      setSchools(schoolList);
    }

    getSchools(shape);
  }, [])

  const onSubmit = async () => {
    const callback = (res: CreateResponse) => {
      history.push(showEducationalBackgroundGroupsPathCreator(res.id))
    }

    const errCallback = (err: CreateErrors) => {
      setErrors(err);
    }

    const forbiddenCallback = () => { setIsForbidden(true); }

    await create(createData, callback, errCallback, forbiddenCallback);
  }

  const Page = () => {
    return (
      <Layout>
        <PageTitle title="学歴グループ登録" />
        <Panel className="col-6">
          <EducationalBackgroundGroupForm
            formData={createData}
            setFormData={setCreateData}
            errors={errors}
            schools={schools}
            setSchools={setSchools}
            onSubmit={onSubmit}
          />
        </Panel>
      </Layout>
    )
  }

  if (isForbidden) {
    return <Forbidden />
  } else {
    return Page();
  }
}

export default New;