import { FC, useEffect, useState } from "react";
import Layout from "layouts/Layout";
import PageTitle from "components/PageTitle";
import Panel from "components/Panel";
import PanelTitle from "components/PanelTitle";
import Table from "components/Table";
import { Breadcrumb, Button, Row } from "react-bootstrap";
import { RouteComponentProps, useHistory } from "react-router";
import { show as getCompany, Company } from "libs/apiClient/admin/companies";
import { show as getCampaign, Campaign } from "libs/apiClient/admin/campaigns";
import CustomFormItems from "./components/customFormItems";
import Schedules from "./components/schedules";
import ThanksMailForm from "./components/thanksMailForm";
import RemindMailForm from "./components/remindMailForm";
import EntrySpreadsheet from "./components/entrySpreadsheetForm";
import {
  newCompanyCampaignCreativesPathCreator,
  editCompanyCampaignCreativesPathCreator,
} from "routes/companies/campaigns/creatives/path";
import style from "./show.module.scss";
import Forbidden from "pages/forbidden";
import { Link } from "react-router-dom";

type urlProps = {} & RouteComponentProps<{ companyId: string; id: string }>;

const Show: FC<urlProps> = (props: urlProps) => {
  const history = useHistory();
  const [company, setCompany] = useState<Company>();
  const [campaign, setCampaign] = useState<Campaign>();
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);
  const companyId = props.match.params.companyId;
  const campaignId = props.match.params.id;

  useEffect(() => {
    const forbiddenCallback = () => {
      setIsForbidden(true);
    };

    getCompany(companyId, setCompany, forbiddenCallback);
    getCampaign(campaignId, setCampaign, forbiddenCallback);
  }, []);

  const Page = () => {
    return (
      <>
        {campaign && (
          <Layout>
            <Breadcrumb>
              <Breadcrumb.Item href="/companies">企業一覧</Breadcrumb.Item>
              <Breadcrumb.Item
                linkAs={Link}
                linkProps={{ to: `/companies/${companyId}` }}
              >
                {company?.name}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>キャンペーン詳細</Breadcrumb.Item>
            </Breadcrumb>
            <PageTitle title="キャンペーン詳細" />
            <Panel className="pb-4">
              <PanelTitle title="基本情報" />
              <p>id: {company?.id}</p>
              <p>企業名: {company?.name}</p>
              <p>キャンペーン名: {campaign.campaignable.name}</p>
            </Panel>
            {campaign.entryForm && (
              <Panel className="pb-4">
                <CustomFormItems entryFormId={campaign.entryForm.id} />
              </Panel>
            )}
            <Panel className="pb-4">
              <Schedules campaignId={campaign.id} />
            </Panel>
            <Row className="pb-4">
              <Panel className="col-6 pb-2">
                <PanelTitle title="サンクスメール" />
                <ThanksMailForm
                  thanksMail={campaign.thanksMail}
                  campaignId={campaign.id}
                />
              </Panel>
              <Panel className="col-6 pb-2">
                <PanelTitle title="リマインドメール" />
                <RemindMailForm
                  remindMail={campaign.remindMail}
                  campaignId={campaign.id}
                />
              </Panel>
            </Row>
            <Panel className="pb-4">
              <PanelTitle title="スプレッドシート連携" />
              <EntrySpreadsheet
                entrySpreadsheet={campaign.entrySpreadsheet}
                campaignId={campaign.id}
              />
            </Panel>
            <Panel>
              <PanelTitle title="クリエイティブ">
                <Button
                  variant="primary"
                  onClick={() =>
                    history.push(
                      newCompanyCampaignCreativesPathCreator(
                        companyId,
                        campaign.id
                      )
                    )
                  }
                >
                  新規作成
                </Button>
              </PanelTitle>
              <Table>
                <thead>
                  <tr>
                    <th>サムネイル</th>
                    <th>管理用タイトル</th>
                    <th>メモ</th>
                    <th>ステータス</th>
                    <th>デフォルト表示</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody>
                  {campaign.creatives.map((creative) => {
                    return (
                      <tr key={creative.id}>
                        <td className="align-middle">
                          <img
                            className={`${style.thumbnail}`}
                            src={creative.thumbnail.url}
                          />
                        </td>
                        <td className={`align-middle ${style.title}`}>
                          {creative.administrativeTitle}
                        </td>
                        <td className={`align-middle ${style.memo}`}>
                          {creative.memo}
                        </td>
                        <td className={`align-middle`}>
                          {creative.isPublished ? "公開" : "下書き"}
                        </td>
                        <td className={`align-middle`}>
                          {String(creative.isListed)}
                        </td>
                        <td className={`align-middle`}>
                          <Button
                            variant="dark"
                            onClick={() =>
                              history.push(
                                editCompanyCampaignCreativesPathCreator(
                                  companyId,
                                  campaign.id,
                                  creative.id
                                )
                              )
                            }
                          >
                            編集
                          </Button>
                          {/* <Button
                            variant="outline-info"
                            className="ml-3"
                            onClick={() => history.push(showCompanyCampaignCreativesPathCreator(companyId, campaign.id, creative.id))}
                          >
                            詳細
                          </Button> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Panel>
          </Layout>
        )}
      </>
    );
  };

  if (isForbidden) {
    return <Forbidden />;
  } else {
    return Page();
  }
};

export default Show;
