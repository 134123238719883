import { FC, Dispatch } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Item } from 'components/Selectize';
import { RequestData, Errors } from 'libs/apiClient/admin/users';
import { Area } from 'libs/apiClient/admin/areas';
import { Prefecture } from 'libs/apiClient/prefectures';
import { School, indexPath as SchoolPath } from 'libs/apiClient/schools';
import { SchoolSpecialtyGroup } from 'libs/apiClient/schoolSpecialtyGroups';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';

type Year = {
  year: number;
  month: number;
};

type Department = {
  department: string;
  id: number;
}

type FormProps = {
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
  schools: School[];
  schoolSpecialtyGroups: SchoolSpecialtyGroup[];
  areas: Area[];
  prefectures: Prefecture[];
  graduationYears: Item<Year>[];
  targetSchool: School;
  setTargetSchool: Dispatch<React.SetStateAction<School>>;
  onSubmit: () => void;
  onClose: () => void;
}

const UserCreateForm: FC<FormProps> = (props) => {
  const {
    formData,
    setFormData,
    errors,
    schools,
    schoolSpecialtyGroups,
    areas,
    prefectures,
    graduationYears,
    targetSchool,
    setTargetSchool,
    onSubmit,
    onClose
  } = props;

  const onChange = (value: RequestData[keyof RequestData], key: keyof RequestData): void => {
    const data = { ...formData, [key]: value }
    setFormData(data);
  }

  const targetSchoolOptions = () => {
    const dataList: string[] = [];
    schools.map((school) => {
      if (school.searchKey == targetSchool.searchKey && school.degree == targetSchool.degree) {
        dataList.push(school.name)
      }
    })
    return [...new Set(dataList)];
  }

  const targetFacultyOptions = () => {
    const dataList: string[] = [];
    schools.map((school) => {
      if (school.name == targetSchool.name && school.degree == targetSchool.degree) {
        dataList.push(school.faculty)
      }
    })
    return [...new Set(dataList)];
  }

  const targetDepartmentOptions = (): Department[] => {
    const dataList: Department[] = [];
    schools.map((school) => {
      if (school.name == targetSchool.name && school.faculty == targetSchool.faculty && school.degree == targetSchool.degree) {
        const data = { department: school.department, id: school.id }
        dataList.push(data);
      }
    })
    return [...new Set(dataList)];
  }

  const onSchoolSelected = (value: School[keyof School], key: keyof School): void => {
    switch (key) {
      case 'degree':
        setTargetSchool({ ...targetSchool, [key]: String(value), ['id']: 0, ['searchKey']: '', ['name']: '', ['faculty']: '', ['department']: '' });
        break;
      case 'searchKey':
        setTargetSchool({ ...targetSchool, [key]: String(value), ['id']: 0, ['name']: '', ['faculty']: '', ['department']: '' });
        break;
      case 'name':
        setTargetSchool({ ...targetSchool, [key]: String(value), ['id']: 0, ['faculty']: '', ['department']: '' });
        break;
      case 'faculty':
        setTargetSchool({ ...targetSchool, [key]: String(value), ['id']: 0, ['department']: '' });
    }
    // schoolIdを初期化
    const data = { ...formData, ['schoolId']: null }
    setFormData(data);
  }

  const onDepartmentChange = (value: RequestData[keyof RequestData], key: keyof RequestData): void => {
    const data = { ...formData, [key]: value }
    setFormData(data);
    setTargetSchool({ ...targetSchool, ['id']: Number(value), ['department']: schools.filter(school => (school.id == value))[0].department });
  }

  return (
    <Panel className='pb-4'>
      <PanelTitle title={'ユーザー新規作成（イベント登録）'} />
      <Form noValidate className='col-6'>
        <Form.Group controlId='graduationYear'>
          <Form.Label>卒年</Form.Label>
          <Form.Control
            as="select"
            custom
            onChange={e => { onChange(e.target.value, 'graduateYear') }}
            isInvalid={errors.graduateYear && errors.graduateYear?.length > 0}
          >
            <option></option>
            {
              graduationYears.map((graduationYear: Item<Year>) => {
                return (
                  <option
                    key={`graduationYear-${graduationYear.id}`}
                    value={graduationYear.value.year}
                  >
                    {graduationYear.label}
                  </option>)
              })
            }
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.graduateYear?.map((err, i) => <p key={`error-graduateYear-${i}`}>{err}</p>)}
          </Form.Control.Feedback>
        </Form.Group>

        <Row>
          <Form.Group as={Col} md="6" controlId='lastName'>
            <Form.Label>姓</Form.Label>
            <Form.Control
              type='text'
              name="lastName"
              value={formData.lastName}
              onChange={e => onChange(e.target.value, 'lastName')}
              placeholder={'姓を入力してください'}
              isInvalid={errors.lastName && errors.lastName?.length > 0}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName?.map((err, i) => <p key={`error-lastName-${i}`}>{err}</p>)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="6" controlId='firstName'>
            <Form.Label>名</Form.Label>
            <Form.Control
              type='text'
              name="firstName"
              value={formData.firstName}
              onChange={e => onChange(e.target.value, 'firstName')}
              placeholder={'名を入力してください'}
              isInvalid={errors.firstName && errors.firstName?.length > 0}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName?.map((err, i) => <p key={`error-firstName-${i}`}>{err}</p>)}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md="6" controlId='lastNameKana'>
            <Form.Label>セイ</Form.Label>
            <Form.Control
              type='text'
              name="lastNameKana"
              value={formData.lastNameKana}
              onChange={e => onChange(e.target.value, 'lastNameKana')}
              placeholder={'セイを入力してください'}
              isInvalid={errors.lastNameKana && errors.lastNameKana?.length > 0}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastNameKana?.map((err, i) => <p key={`error-lastNameKana-${i}`}>{err}</p>)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="6" controlId='firstNameKana'>
            <Form.Label>メイ</Form.Label>
            <Form.Control
              type='text'
              name="firstNameKana"
              value={formData.firstNameKana}
              onChange={e => onChange(e.target.value, 'firstNameKana')}
              placeholder={'メイを入力してください'}
              isInvalid={errors.firstNameKana && errors.firstNameKana?.length > 0}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstNameKana?.map((err, i) => <p key={`error-firstNameKana-${i}`}>{err}</p>)}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Form.Group controlId='phoneNumber'>
          <Form.Label>電話番号</Form.Label>
          <Form.Control
            type='text'
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={e => onChange(e.target.value, 'phoneNumber')}
            placeholder={'09012345678'}
            isInvalid={errors.phoneNumber && errors.phoneNumber?.length > 0}
          />
          <Form.Control.Feedback type="invalid">
            {errors.phoneNumber?.map((err, i) => <p key={`error-phoneNumber-${i}`}>{err}</p>)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='email'>
          <Form.Label>メールアドレス</Form.Label>
          <Form.Control
            type='text'
            name="email"
            value={formData.email}
            onChange={e => onChange(e.target.value, 'email')}
            placeholder={'shutatsu-times@gmail.com'}
            isInvalid={errors.email && errors.email?.length > 0}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email?.map((err, i) => <p key={`error-email-${i}`}>{err}</p>)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='prefectureId'>
          <Form.Label>お住いの都道府県</Form.Label>
          <Form.Control
            as="select"
            custom
            onChange={e => { onChange(e.target.value, 'prefectureId') }}
            isInvalid={errors.prefectureId && errors.prefectureId?.length > 0}
          >
            <option></option>
            {
              prefectures.map((prefecture: Prefecture) => {
                return (
                  <option
                    key={`prefecture-${prefecture.id}`}
                    value={prefecture.id}
                  >
                    {prefecture.name}
                  </option>)
              })
            }
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.prefectureId?.map((err, i) => <p key={`error-prefectureId-${i}`}>{err}</p>)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='gender'>
          <Form.Label>性別</Form.Label>
          <div className={``}>
            <Form.Check
              inline
              id='man'
              type={'radio'}
              name='gender'
              label='男性'
              onChange={_e => onChange('man', 'gender')}
            />
            <Form.Check
              inline
              id='woman'
              type={'radio'}
              name='gender'
              label='女性'
              onChange={_e => onChange('woman', 'gender')}
            />
            <Form.Check
              inline
              id='other'
              type={'radio'}
              name='gender'
              label='その他'
              onChange={_e => onChange('other', 'gender')}
            />
          </div>
        </Form.Group>

        <Form.Group controlId='shooleSpecialityGroups'>
          <Form.Label>文理</Form.Label>
          <div>
            {
              schoolSpecialtyGroups && schoolSpecialtyGroups.map(group => {
                return (
                  <Form.Check
                    key={`school-specialty-groups-${group.id}`}
                    inline
                    id={group.specialtyGroup}
                    type={'radio'}
                    name='schoolSpecialtyGroups'
                    label={group.label}
                    onChange={_e => onChange(group.id, 'schoolSpecialtyGroupId')}
                  />
                )
              })
            }
          </div>
        </Form.Group>

        <Form.Group controlId='degree'>
          <Form.Label>最終学歴</Form.Label>
          <Form.Control
            as="select"
            custom
            onChange={e => { onSchoolSelected(e.target.value, 'degree') }}
            isInvalid={errors.schoolId && errors.schoolId?.length > 0}
          >
            <option></option>
            <option value='undergraduate_degree'>大学卒業見込み</option>
            <option value='master_degree'>大学院(修士)卒業見込み</option>
            <option value='junior_college_degree'>短大卒業見込み</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.schoolId?.map((err, i) => <p key={`error-schoolId-${i}`}>{err}</p>)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='searchKey'>
          <Form.Label>学校検索</Form.Label>
          <Form.Control
            as="select"
            custom
            value={targetSchool.searchKey || ''}
            onChange={e => { onSchoolSelected(e.target.value, 'searchKey') }}
            isInvalid={errors.schoolId && errors.schoolId?.length > 0}
          >
            <option></option>
            <option value="あ">あ</option>
            <option value="い">い</option>
            <option value="う">う</option>
            <option value="え">え</option>
            <option value="お">お</option>
            <option value="か">か</option>
            <option value="き">き</option>
            <option value="く">く</option>
            <option value="け">け</option>
            <option value="こ">こ</option>
            <option value="さ">さ</option>
            <option value="し">し</option>
            <option value="す">す</option>
            <option value="せ">せ</option>
            <option value="そ">そ</option>
            <option value="た">た</option>
            <option value="ち">ち</option>
            <option value="つ">つ</option>
            <option value="て">て</option>
            <option value="と">と</option>
            <option value="な">な</option>
            <option value="に">に</option>
            <option value="の">の</option>
            <option value="は">は</option>
            <option value="ひ">ひ</option>
            <option value="ふ">ふ</option>
            <option value="へ">へ</option>
            <option value="ほ">ほ</option>
            <option value="ま">ま</option>
            <option value="み">み</option>
            <option value="む">む</option>
            <option value="め">め</option>
            <option value="も">も</option>
            <option value="や">や</option>
            <option value="よ">よ</option>
            <option value="ら">ら</option>
            <option value="り">り</option>
            <option value="る">る</option>
            <option value="れ">れ</option>
            <option value="わ">わ</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.schoolId?.map((err, i) => <p key={`error-schoolId-${i}`}>{err}</p>)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='name'>
          <Form.Label>学校名</Form.Label>
          <Form.Control
            as="select"
            custom
            value={targetSchool.name || ''}
            onChange={e => { onSchoolSelected(e.target.value, 'name') }}
            isInvalid={errors.schoolId && errors.schoolId?.length > 0}
          >
            <option></option>
            {
              targetSchoolOptions().map((targetSchoolOption: any, i) => {
                return (
                  <option
                    key={`schoolName-${i}`}
                    value={targetSchoolOption}
                  >
                    {targetSchoolOption}
                  </option>)
              })
            }
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.schoolId?.map((err, i) => <p key={`error-schoolId-${i}`}>{err}</p>)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='faculty'>
          <Form.Label>学部名</Form.Label>
          <Form.Control
            as="select"
            custom
            value={targetSchool.faculty || ''}
            onChange={e => { onSchoolSelected(e.target.value, 'faculty') }}
            isInvalid={errors.schoolId && errors.schoolId?.length > 0}
          >
            <option></option>
            {
              targetFacultyOptions().map((targetSchoolOption, i) => {
                return (
                  <option
                    key={`schoolFaculty-${i}`}
                    value={targetSchoolOption}
                  >
                    {targetSchoolOption}
                  </option>)
              })
            }
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.schoolId?.map((err, i) => <p key={`error-schoolId-${i}`}>{err}</p>)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='department'>
          <Form.Label>学科名</Form.Label>
          <Form.Control
            as="select"
            custom
            value={targetSchool.id || ''}
            onChange={e => { onDepartmentChange(e.target.value, 'schoolId') }}
            isInvalid={errors.schoolId && errors.schoolId?.length > 0}
          >
            <option></option>
            {
              targetDepartmentOptions().map((targetSchoolOption, i) => {
                return (
                  <option
                    key={`schoolDepartment-${i}`}
                    value={targetSchoolOption.id}
                  >
                    {targetSchoolOption.department}
                  </option>)
              })
            }
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.schoolId?.map((err, i) => <p key={`error-schoolId-${i}`}>{err}</p>)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Button type="button" className='mr-2' variant="outline-secondary" onClick={onClose}>キャンセル</Button>
          <Button type="button" variant='outline-success' onClick={onSubmit}>保存</Button>
        </Form.Group>
      </Form>
    </Panel>
  )
}

export default UserCreateForm;
