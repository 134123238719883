import { Prefecture } from 'libs/apiClient/prefectures';
import { School } from 'libs/apiClient/schools';
import { SchoolSpecialtyGroup } from 'libs/apiClient/schoolSpecialtyGroups';

export const indexPath = (keyword: string | number | null = null): string => {
  const queryParams = keyword ? new URLSearchParams([['keyword', String(keyword)]]) : '';
  return `api/v1/admin/users?${queryParams}`;  
}
export const createPath = 'api/v1/admin/users';
export const showPath = (id: string | number) => `api/v1/admin/users/${id}`
export const updatePath = (id: string | number) => `api/v1/admin/users/${id}`

export type User = {
  userId: number;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  email: string;
  phoneNumber: number | string;
  graduateYear: number;
  graduateMonth: number;
  prefecture: Prefecture;
  gender: string;
  schoolSpecialtyGroup: SchoolSpecialtyGroup;
  school: School;
  jobHuntingStatus: string;
  isEmailAvailable: boolean;
  isPhoneAvailable: boolean;
}

export type RequestData = {
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  email: string;
  phoneNumber: number | string;
  graduateYear: number;
  graduateMonth: number;
  prefectureId: number | null;
  gender: string;
  schoolSpecialtyGroupId: number | null;
  schoolId: number | null;
  jobHuntingStatus: string;
  isEmailAvailable: boolean;
  isPhoneAvailable: boolean;
}

export type Response = {
  id: number | string;
}

export type Errors = {
  firstName?: string[];
  lastName?: string[];
  firstNameKana?: string[];
  lastNameKana?: string[];
  email?: string[];
  phoneNumber?: string[];
  graduateYear?: string[];
  graduateMonth?: string[];
  prefectureId?: string[];
  gender?: string[];
  schoolSpecialtyGroupId?: string[];
  schoolId?: string[];
}
