import { get, post, patch } from "libs/fetch";

/* get request */
export type Tag = {
  id: number;
  name: string;
};

export const index = async (
  callback: (responseData: Tag[]) => void,
  forbiddenCallback?: () => void
) => {
  const res = await get("api/v1/admin/tags");
  const body = await res.json();

  if (res.status === 200) {
    const responseData: Tag[] = body.tags;
    callback(responseData);
  } else if (res.status == 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
};

export const show = async (
  id: number | string,
  callback: (responseData: Tag) => void,
  forbiddenCallback?: () => void
) => {
  const res = await get(`api/v1/admin/tags/${id}`);
  const body = await res.json();

  if (res.status === 200) {
    const responseData: Tag = body.tag;
    callback(responseData);
  } else if (res.status == 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
};

/* post request */
export type CreateData = {
  name: string;
};

export type CreateResponse = {
  id: number;
};

export type CreateErrors = {
  name: string[];
};

export const create = async (
  data: CreateData,
  callback: (responseData: CreateResponse) => void,
  errCallback: (error: CreateErrors) => void,
  forbiddenCallback?: () => void
) => {
  const res = await post("api/v1/admin/tags", { tag: data });
  const body = await res.json();

  if (res.status == 200) {
    const responseData: CreateResponse = body.tag;
    callback(responseData);
  } else if (res.status == 400) {
    const errors: CreateErrors = body.errors;
    errCallback(errors);
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }
};

/* patch request */
export type UpdateData = {
  name: string;
};

export type UpdateResponse = {
  id: number;
};

export type UpdateErrors = {
  name: string[];
};

export const update = async (
  id: number | string,
  data: UpdateData,
  callback: (responseData: UpdateResponse) => void,
  errCallback: (err: UpdateErrors) => void,
  forbiddenCallback?: () => void
) => {
  const res = await patch(`api/v1/admin/tags/${id}`, { tag: data });
  const body = await res.json();

  if (res.status == 200) {
    const responseData: UpdateResponse = body.tag;
    callback(responseData);
  } else if (res.status == 400) {
    const errors: UpdateErrors = body.errors;
    errCallback(errors);
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }
};
