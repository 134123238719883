import { Area } from 'libs/apiClient/admin/areas';
import { Prefecture } from 'libs/apiClient/prefectures';

export const indexPath = (queryParamsKey: string | null = null, queryParamsValue: string | number | null = null): string => {
  const queryParams = queryParamsKey ? new URLSearchParams([[queryParamsKey, String(queryParamsValue)]]) : '';
  return `api/v1/admin/schedules?${queryParams}`;
}
export const createPath = 'api/v1/admin/schedules';
// export const showPath = (id: string | number): string => `api/v1/admin/custom_form_items/${id}`;
export const updatePath = (id: string | number): string => `api/v1/admin/schedules/${id}`;
export const destroyPath = (id: string | number): string => `api/v1/admin/schedules/${id}`;

export type Schedule = {
  id: number | string;
  isOnline: boolean;
  onlineText: string;
  postCode: string;
  prefecture: Prefecture;
  address1: string;
  address2: string;
  station: string;
  isSchedule: boolean;
  startAt: string;
  endAt: string;
  noScheduleText: string;
  isPublished: boolean;
  openAt: string;
  closeAt: string;
  participantLimit: number;
  remarks: string;
  campaignId: number | string;
  area: Area;
  targetPrefectures: Prefecture[];
  targetSchoolSpecialtyGroups: SchoolSpecialityGroup[];
  campaignable: Campaignable;
  entryFormId: number | string | null;
}

type SchoolSpecialityGroup = {
  id: number;
  label: string;
  specialtyGroup: string;
}

type Campaignable = {
  name: string;
  memo: string;
  startAt: string;
  closeAt: string;
}

export type RequestData = {
  postCode?: string;
  prefectureId?: number | string;
  address_1?: string;
  address_2?: string;
  station?: string;
  remarks: string;
  openAt: string;
  closeAt: string;
  startAt?: string;
  endAt?: string;
  isSchedule: boolean;
  noScheduleText?: string;
  isOnline: boolean;
  isPublished: boolean;
  onlineText?: string;
  participantLimit: number;
  areaId: number | null;
  targetPrefectureIds: number[];
  targetSchoolSpecialtyGroupIds: number[];
}

export type Response = {
  id: number | string;
}

export type Errors = {
  postCode?: string[];
  prefectureId?: string[];
  address1?: string[];
  address2?: string[];
  station?: string[];
  remarks?: string[];
  openAt?: string[];
  closeAt?: string[];
  startAt?: string[];
  endAt?: string[];
  isSchedule?: string[];
  noScheduleText?: string[];
  isOnline?: string[];
  isPublished?: string[];
  onlineText?: string[];
  participantLimit?: string[];
  areaId?: string[];
  targetPrefectureIds?: string[];
  targetSchoolSpecialtyGroupIds?: string[];
}