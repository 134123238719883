import { FC, useState } from "react";
import Layout from "layouts/Layout";
import PageTitle from "components/PageTitle";
import Panel from "components/Panel";
import { Breadcrumb, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  createPath,
  RequestData,
  Errors,
  Response,
} from "libs/apiClient/admin/creatives";
import { createFormData } from "libs/apiClient";
import { showCompanyCampaignPathCreator } from "routes/companies/campaigns/path";
import CreativeForm from "../components/CreativeForm";
import ImagePicker from "../components/ImagePicker";
import Forbidden from "pages/forbidden";

type urlProps = {} & RouteComponentProps<{
  companyId: string;
  campaignId: string;
}>;

const New: FC<urlProps> = (props) => {
  const history = useHistory();
  const companyId = props.match.params.companyId;
  const campaignId = props.match.params.campaignId;
  const [formData, setFormData] = useState<RequestData>({
    administrativeTitle: "",
    memo: "",
    thumbnail: null,
    title: "",
    abstruct: "",
    body: "",
    style: "",
    isListed: false,
    isPublished: false,
    campaignId: campaignId,
  });
  const [errors, setErrors] = useState<Errors>({});
  const [thumbnail, setThumbnail] = useState<string | undefined>();
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  const onSubmit = (requestData: FormData) => {
    const callback = (_res: Response) => {
      history.push(showCompanyCampaignPathCreator(companyId, campaignId));
    };

    const errCallback = (err: Errors) => {
      setErrors(err);
    };

    const forbiddenCallback = () => {
      setIsForbidden(true);
    };

    createFormData(
      createPath,
      requestData,
      "creative",
      callback,
      errCallback,
      forbiddenCallback
    );
  };

  const Page = () => {
    return (
      <Layout>
        <Breadcrumb>
          <Breadcrumb.Item href="/companies">企業一覧</Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: `/companies/${companyId}` }}
          >
            企業詳細
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{
              to: `/companies/${companyId}/campaigns/${campaignId}`,
            }}
          >
            キャンペーン詳細
          </Breadcrumb.Item>
          <Breadcrumb.Item active>クリエイティブ作成</Breadcrumb.Item>
        </Breadcrumb>
        <PageTitle title="クリエイティブ作成" />
        <Row>
          <Panel className="col-6 p-2">
            <CreativeForm
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              onSubmitFormData={onSubmit}
              thumbnail={thumbnail}
              setThumbnail={setThumbnail}
            />
          </Panel>
          <Panel className="col-6 p-2 pl-0">
            <ImagePicker />
          </Panel>
        </Row>
      </Layout>
    );
  };

  if (isForbidden) {
    return <Forbidden />;
  } else {
    return Page();
  }
};

export default New;
