import { FC, ReactNode, Dispatch } from 'react';
import { Toast } from 'react-bootstrap';
import style from './Toast.module.scss';

type ToastProps = {
  message?: string|null;
  isDispayToast: boolean;
  setIsDispayToast: Dispatch<React.SetStateAction<boolean>>;
}

const ToastContainer:FC<ToastProps> = (props) => {
  const { message, isDispayToast, setIsDispayToast } = props;

  return (
    <div style={{textAlign: 'center'}}>
      <Toast
        onClose={() => setIsDispayToast(false)}
        show={isDispayToast}
        className={ `${style.toast}` }
        delay={3000}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto">{message}</strong>
        </Toast.Header>
      </Toast>
    </div>
  );
}

ToastContainer.defaultProps = {
  message: '完了しました'
}


export default ToastContainer;
