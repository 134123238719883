import { FC, useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import {
  indexPath,
  updatePath,
  User,
  RequestData,
  Errors,
  Response
} from 'libs/apiClient/admin/users';
import { get, update } from 'libs/apiClient';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import Table from 'components/Table';
import { Item } from 'components/Selectize';
import { Prefecture, indexPath as PrefecturesPath } from 'libs/apiClient/prefectures';
import { School, indexPath as SchoolsPath } from 'libs/apiClient/schools';
import { SchoolSpecialtyGroup, indexPath as SchoolSpecialtyGroupsPath } from 'libs/apiClient/schoolSpecialtyGroups';
import { Area, indexPath as AreasPath } from 'libs/apiClient/admin/areas';
import UserUpdateForm from './UserUpdateForm';
import Toast from 'components/Toast';

type Year = {
  year: number;
  month: number;
};

const Users: FC = () => {
  const formDataTemplate = {
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    email: '',
    phoneNumber: '',
    graduateYear: 2023,
    graduateMonth: 3,
    prefectureId: null,
    gender: '',
    schoolSpecialtyGroupId: null,
    schoolId: null,
    jobHuntingStatus: 'active',
    isEmailAvailable: true,
    isPhoneAvailable: true
  }
  const targetSchoolDataTemplate = {
    id: 0,
    name: '',
    degree: '',
    faculty: '',
    department: '',
    searchKey: '',
  }
  const [formUpdateData, setFormUpdateData] = useState<RequestData>(formDataTemplate);
  const [errors, setErrors] = useState<Errors>({});
  const [isDisplayUpdateForm, setIsDisplayUpdateForm] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | number | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string | number | null>(null);
  const [areas, setAreas] = useState<Area[]>([]);
  const [prefectures, setPrefectures] = useState<Prefecture[]>([]);
  const [schools, setSchools] = useState<School[]>([]);
  const [schoolSpecialtyGroups, setSchoolSpecialtyGroups] = useState<SchoolSpecialtyGroup[]>([]);
  const [graduationYears, setGraduationYears] = useState<Item<Year>[]>([]);
  const [targetSchoolUpdateData, setTargetSchoolUpdateData] = useState<School>(targetSchoolDataTemplate);
  const [isDispayToast, setIsDispayToast] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    // 文理マスターの取得
    get(SchoolSpecialtyGroupsPath, 'schoolSpecialtyGroups', setSchoolSpecialtyGroups);

    // 都道府県マスターの取得
    get(PrefecturesPath, 'prefectures', setPrefectures);

    // 卒年マスターの取得
    const targetYears = [0, 1, 2, 3, 4, 5].map(n => new Date().getFullYear() + n)
    const yearTemplate = (id: number, year: number, month: number): Item<Year> => {
      return {
        id: id,
        label: `${year}年`,
        value: { year: year, month: month },
        isDisplay: true,
        isSelected: false
      }
    }
    const newYears: Item<Year>[] = targetYears.map((year, i) => {
      return yearTemplate(i + 1, year, 3);
    })
    setGraduationYears(newYears);

    // 学校マスターの取得
    get(SchoolsPath, 'schools', setSchools);
  }, []);

  const onCloseUpdateForm = () => {
    setIsDisplayUpdateForm(false);
    setFormUpdateData(formDataTemplate);
    setTargetSchoolUpdateData(targetSchoolDataTemplate);
    setUserId(null)
  }

  const onSubmitUpdateForm = () => {
    const callback = (_res: Response) => {
      setMessage('ユーザー情報を更新しました');
      setIsDispayToast(true);
      setIsDisplayUpdateForm(false);
      setFormUpdateData(formDataTemplate);
      setTargetSchoolUpdateData(targetSchoolDataTemplate);
      setUserId(null);
      setErrors({});
      // 更新したユーザー情報を取得
      get(indexPath(searchKeyword), 'users', setUsers);
    }

    const errCallback = (err: Errors) => {
      setErrors(err)
    }
    // MEMO: パスワード設定がされていないユーザーの場合、auth0側を更新できないため更新に失敗するが、レスポンスは200が返ってくる
    userId && update(updatePath(userId), { user: { ...formUpdateData } }, 'user', callback, errCallback);
  }

  const onSearch = () => {
    const callback = (res: User[]) => {
      setUsers(res);
      if (res.length == 0) {
        setMessage('該当するユーザーは見つかりませんでした');
        setIsDispayToast(true);
      }
    }

    get(indexPath(searchKeyword), 'users', callback)
  }

  const onUpdate = (user: User) => {
    setIsDisplayUpdateForm(true);

    setFormUpdateData({
      firstName: user.firstName,
      lastName: user.lastName,
      firstNameKana: user.firstNameKana,
      lastNameKana: user.lastNameKana,
      email: user.email,
      phoneNumber: user.phoneNumber,
      graduateYear: user.graduateYear,
      graduateMonth: user.graduateMonth,
      prefectureId: user.prefecture.id,
      gender: user.gender,
      schoolSpecialtyGroupId: user.schoolSpecialtyGroup && user.schoolSpecialtyGroup.id,
      schoolId: user.school && user.school.id,
      jobHuntingStatus: user.jobHuntingStatus,
      isEmailAvailable: user.isEmailAvailable,
      isPhoneAvailable: user.isPhoneAvailable
    })
    const targetSchool = {
      id: user.school && user.school.id,
      name: user.school && user.school.name,
      degree: user.school && user.school.degree,
      faculty: user.school && user.school.faculty,
      department: user.school && user.school.department,
      searchKey: user.school && user.school.searchKey,
    };
    setTargetSchoolUpdateData(targetSchool);
    setUserId(user.userId);
    setErrors({});
  }

  return (
    <>
      <Panel className='pb-4'>
        <PanelTitle title='ユーザー検索' />
        <Form className='col-6'>
          <Form.Group controlId='userSearch'>
            <Form.Label>メールアドレス or 電話番号</Form.Label>
            <Form.Control
              type='text'
              name="userSearch"
              onChange={e => setSearchKeyword(e.target.value)}
            />
          </Form.Group>
          <Button type="button" variant='outline-primary' onClick={onSearch}>検索</Button>
        </Form>
      </Panel>

      <Panel className='pb-4'>
        <PanelTitle title='検索結果' />
        <Table className='mb-2'>
          <thead>
            <tr>
              <th>ID</th>
              <th>姓</th>
              <th>名</th>
              <th>メールアドレス</th>
              <th>電話番号</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {users && (
              users.map(user => {
                return (
                  <tr key={user.userId}>
                    <td className={`align-middle`} >{user.userId}</td>
                    <td className={`align-middle`} >{user.lastName}</td>
                    <td className={`align-middle`} >{user.firstName}</td>
                    <td className={`align-middle`} >{user.email}</td>
                    <td className={`align-middle`} >{user.phoneNumber}</td>
                    <td className={`align-middle`} >
                      <Button
                        variant='dark'
                        onClick={() => onUpdate(user)}
                      >
                        詳細
                      </Button>
                    </td>
                  </tr>
                )
              })
            )}
          </tbody>
        </Table>
        {
          isDisplayUpdateForm && (
            <>
              <UserUpdateForm
                formData={formUpdateData}
                setFormData={setFormUpdateData}
                errors={errors}
                areas={areas}
                schools={schools}
                schoolSpecialtyGroups={schoolSpecialtyGroups}
                prefectures={prefectures}
                graduationYears={graduationYears}
                targetSchool={targetSchoolUpdateData}
                setTargetSchool={setTargetSchoolUpdateData}
                userId={userId}
                onSubmit={onSubmitUpdateForm}
                onClose={onCloseUpdateForm}
              />
            </>
          )
        }
      </Panel>

      {isDispayToast && (
        <Toast
          message={message}
          isDispayToast={isDispayToast}
          setIsDispayToast={setIsDispayToast}
        />
      )
      }
    </>
  )
}

export default Users;
