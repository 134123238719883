import { postFormData } from 'libs/fetch';

export const useBulkInsert = async (data: any) => {
  const response = await postFormData('api/v1/admin/schedules/bulk_insert', data)
  if (response.ok) {
    return { status: response.status, result: true }
  }

  const body = await response.json();
  return { status: response.status, result: false, errors: body.errors }
}