import { FC, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import {
  indexPath,
  createPath,
  updatePath,
  destroyPath,
  Schedule,
  RequestData,
  Errors,
  Response,
} from "libs/apiClient/admin/schedules";
import { get, create, update, destroy } from "libs/apiClient";
import PanelTitle from "components/PanelTitle";
import Table from "components/Table";
import ScheduleForm from "./ScheduleForm";
import { CsvImportForm } from "./CsvImportForm";
import { Item } from "components/Selectize";
import {
  Prefecture,
  indexPath as PrefecturesPath,
} from "libs/apiClient/prefectures";
import {
  SchoolSpecialtyGroup,
  indexPath as SchoolSpecialtyGroupsPath,
} from "libs/apiClient/schoolSpecialtyGroups";
import { Area, indexPath as AreasPath } from "libs/apiClient/admin/areas";
import { nanoid } from "nanoid";

type Props = {
  campaignId: number | string;
};

const Schedules: FC<Props> = (props) => {
  const { campaignId } = props;
  const [schedules, setSchedules] = useState<Schedule[]>();
  const [areas, setAreas] = useState<Area[]>([]);
  const [prefectures, setPrefectures] = useState<Prefecture[]>([]);
  const [schoolSpecialtyGroups, setSchoolSpecialtyGroups] = useState<
    SchoolSpecialtyGroup[]
  >([]);
  const [targetPrefectures, setTargetPrefectures] = useState<Item<number>[]>(
    []
  );
  const formDataTemplate = {
    postCode: "",
    prefectureId: "",
    address_1: "",
    address_2: "",
    station: "",
    openAt: "",
    closeAt: "",
    isSchedule: true,
    noScheduleText: "",
    isOnline: false,
    isPublished: false,
    onlineText: "",
    participantLimit: 1,
    remarks: "",
    areaId: null,
    targetPrefectureIds: [],
    targetSchoolSpecialtyGroupIds: [],
  };
  const [formData, setFormData] = useState<RequestData>(formDataTemplate);
  const [isDisplayForm, setIsDisplayForm] = useState<boolean>(false);
  const [isDisplayCsvImportForm, setIsDisplayCsvImportForm] =
    useState<boolean>(false);
  const [scheduleId, setScheduleId] = useState<number | string | null>(null);
  const [errors, setErrors] = useState<Errors>({});
  const [formKey, setFormKey] = useState<string>(nanoid(8));

  useEffect(() => {
    let isMounted = true;

    // custom form items 取得
    get(indexPath("campaign_id", campaignId), "schedules", setSchedules);
    // 文理マスターの取得
    get(
      SchoolSpecialtyGroupsPath,
      "schoolSpecialtyGroups",
      setSchoolSpecialtyGroups
    );
    // エリアの取得
    get(AreasPath, "areas", setAreas);
    // 都道府県アイテムの取得
    get(PrefecturesPath, "prefectures", (res: Prefecture[]) => {
      setPrefectures(res);
      setSelectizePrefecture(res, (_p) => false);
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const setSelectizePrefecture = (
    prefectures: Prefecture[],
    isSelect: (prefecture: Prefecture) => boolean
  ) => {
    setTargetPrefectures(
      prefectures.map((prefecture) => {
        return {
          id: prefecture.id,
          label: prefecture.name,
          value: prefecture.id,
          isDisplay: true,
          isSelected: isSelect(prefecture),
        };
      })
    );
  };

  const onCsvImport = () => {
    setFormData(formDataTemplate);
    setScheduleId(null);
    setIsDisplayForm(false);
    setIsDisplayCsvImportForm(true);
    setSelectizePrefecture(prefectures, (_p) => false);
    setErrors({});
    setFormKey(nanoid(8));
  };

  const onCreate = () => {
    setFormData(formDataTemplate);
    setScheduleId(null);
    setIsDisplayForm(true);
    setIsDisplayCsvImportForm(false);
    setSelectizePrefecture(prefectures, (_p) => false);
    setErrors({});
    setFormKey(nanoid(8));
  };

  const onClose = () => {
    setIsDisplayForm(false);
    setFormData(formDataTemplate);
    setScheduleId(null);
  };

  const onUpdate = (schedule: Schedule) => {
    setIsDisplayForm(true);

    const targetPrefectureIds = schedule.targetPrefectures?.map((p) => p.id);
    setFormData({
      isOnline: schedule.isOnline,
      onlineText: schedule.onlineText,
      postCode: schedule.postCode,
      prefectureId: schedule.prefecture?.id,
      address_1: schedule.address1,
      address_2: schedule.address2,
      station: schedule.station,
      isSchedule: schedule.isSchedule,
      startAt: schedule.startAt,
      endAt: schedule.endAt,
      noScheduleText: schedule.noScheduleText,
      isPublished: schedule.isPublished,
      openAt: schedule.openAt,
      closeAt: schedule.closeAt,
      participantLimit: schedule.participantLimit,
      remarks: schedule.remarks,
      areaId: schedule.area?.id,
      targetPrefectureIds: targetPrefectureIds,
      targetSchoolSpecialtyGroupIds: schedule.targetSchoolSpecialtyGroups?.map(
        (s) => s.id
      ),
    });
    setSelectizePrefecture(prefectures, (prefecture) =>
      targetPrefectureIds.includes(prefecture.id)
    );
    setScheduleId(schedule.id);
    setErrors({});
    setFormKey(nanoid(8));
  };

  const onSubmit = () => {
    const callback = (_res: Response) => {
      get(indexPath("campaign_id", campaignId), "schedules", setSchedules);
      setIsDisplayForm(false);
      setFormData(formDataTemplate);
      setScheduleId(null);
      setSelectizePrefecture(prefectures, (_p) => false);
      setErrors({});
    };

    const errCallback = (err: Errors) => {
      setErrors(err);
    };

    if (scheduleId) {
      update(
        updatePath(scheduleId),
        { schedule: formData },
        "schedule",
        callback,
        errCallback
      );
    } else {
      create(
        createPath,
        { schedule: { ...formData, campaignId } },
        "schedule",
        callback,
        errCallback
      );
    }
  };

  const onReload = () => {
    get(indexPath("campaign_id", campaignId), "schedules", setSchedules);
  };

  const onDestroy = (id: number | string) => {
    const callback = (_res: Response) => {
      get(indexPath("campaign_id", campaignId), "schedules", setSchedules);
      if (id == scheduleId) {
        setIsDisplayForm(false);
        setFormData(formDataTemplate);
        setScheduleId(null);
        setErrors({});
      }
    };

    const errCallback = (err: Errors) => {
      console.log(err);
    };

    destroy(destroyPath(id), "message", callback, errCallback);
  };

  return (
    <>
      <PanelTitle title="スケジュール">
        <Button
          variant="secondary"
          onClick={(_e) => onReload()}
          className="mr-2"
        >
          リロード
        </Button>
        <Button
          variant="primary"
          onClick={(_e) => onCsvImport()}
          className="mr-2"
        >
          csvインポート
        </Button>
        <Button variant="primary" onClick={(_e) => onCreate()}>
          新規作成
        </Button>
      </PanelTitle>
      <Table className="mb-2">
        <thead>
          <tr>
            <th>ID</th>
            <th>エリア</th>
            <th>開始日時</th>
            <th>終了日時</th>
            <th>参加上限</th>
            <th>公開開始</th>
            <th>公開終了</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {schedules &&
            schedules.map((schedule) => {
              return (
                <tr key={schedule.id}>
                  <td className={`align-middle`}>{schedule.id}</td>
                  <td className={`align-middle`}>{schedule?.area?.name}</td>
                  <td className={`align-middle`}>{schedule.startAt}</td>
                  <td className={`align-middle`}>{schedule.endAt}</td>
                  <td className={`align-middle`}>
                    {schedule.participantLimit}
                  </td>
                  <td className={`align-middle`}>{schedule.openAt}</td>
                  <td className={`align-middle`}>{schedule.closeAt}</td>
                  <td className={`align-middle`}>
                    <Button variant="dark" onClick={() => onUpdate(schedule)}>
                      編集
                    </Button>
                    {/* <Button
                      variant="outline-info"
                      className="ml-3"
                      onClick={() => onDestroy(schedule.id)}
                    >
                      削除
                    </Button> */}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {isDisplayForm && (
        <ScheduleForm
          formKey={formKey}
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          areas={areas}
          schoolSpecialtyGroups={schoolSpecialtyGroups}
          prefectures={prefectures}
          targetPrefectures={targetPrefectures}
          setTargetPrefectures={setTargetPrefectures}
          onSubmit={onSubmit}
          onClose={onClose}
          title={
            scheduleId
              ? `スケジュール編集(ID: ${scheduleId})`
              : "スケジュール作成"
          }
        />
      )}
      {isDisplayCsvImportForm && (
        <CsvImportForm title="csvインポート" campaignId={campaignId} />
      )}
    </>
  );
};

export default Schedules;
