import { get, post, patch } from 'libs/fetch';
import { School } from 'libs/apiClient/schools';

/* get request */
export type EducationalBackgroundGroup = {
  id: number;
  name: string;
  schools: School[];
}

export const index = async (
  callback: (responseData: EducationalBackgroundGroup[]) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await get('api/v1/admin/educational_background_groups');
  const body = await res.json();

  if (res.status === 200) {
    const responseData: EducationalBackgroundGroup[] = body.educationalBackgroundGroups;
    callback(responseData)
  } else if (res.status == 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
}

export const show = async (
  id: string,
  callback: (responseData: EducationalBackgroundGroup) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await get(`api/v1/admin/educational_background_groups/${id}`);
  const body = await res.json();

  if (res.status === 200) {
    const responseData: EducationalBackgroundGroup = body.educationalBackgroundGroup;
    callback(responseData)
  } else if (res.status == 403) {
    forbiddenCallback !== undefined && forbiddenCallback();
  }
}

/* post request */
export type CreateData = {
  name: string;
  schoolIds: number[];
}

export type CreateResponse = {
  id: number;
}

export type CreateErrors = {
  name: string[];
  schoolIds: string[];
}

export const create = async (
  data: CreateData,
  callback: (responseData: CreateResponse) => void,
  errCallback: (error: CreateErrors) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await post('api/v1/admin/educational_background_groups', { educationalBackgroundGroup: data });
  const body = await res.json();

  if (res.status == 200) {
    const responseData: CreateResponse = body.educationalBackgroundGroup;
    callback(responseData)
  } else if (res.status == 400) {
    const errors: CreateErrors = body.errors;
    errCallback(errors);
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }
}

/* patch request */
export type UpdateData = {
  name: string;
  schoolIds: number[];
}

export type UpdateResponse = {
  id: number;
}

export type UpdateErrors = {
  name: string[];
  schoolIds: string[];
}

export const update = async (
  id: number | string,
  data: UpdateData,
  callback: (responseData: UpdateResponse) => void,
  errCallback: (err: UpdateErrors) => void,
  forbiddenCallback?: () => void,
) => {
  const res = await patch(`api/v1/admin/educational_background_groups/${id}`, { educationalBackgroundGroup: data });
  const body = await res.json();

  if (res.status == 200) {
    const responseData: UpdateResponse = body.educationalBackgroundGroup;
    callback(responseData)
  } else if (res.status == 400) {
    const errors: UpdateErrors = body.errors;
    errCallback(errors);
  } else if (res.status === 403) {
    forbiddenCallback != undefined && forbiddenCallback();
  }
}