import { FC, Dispatch } from 'react';
import { Form, Button } from 'react-bootstrap';
import DateTime from 'components/DateTime';
import Selectize, { Item } from 'components/Selectize';
import { RequestData, Errors } from 'libs/apiClient/admin/schedules';
import { Area } from 'libs/apiClient/admin/areas';
import { Prefecture } from 'libs/apiClient/prefectures';
import { SchoolSpecialtyGroup } from 'libs/apiClient/schoolSpecialtyGroups';
import { nanoid } from 'nanoid';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';

type FormProps = {
  formKey: string;
  formData: RequestData;
  setFormData: Dispatch<React.SetStateAction<RequestData>>;
  errors: Errors;
  targetPrefectures: Item<number>[];
  setTargetPrefectures: Dispatch<React.SetStateAction<Item<number>[]>>;
  schoolSpecialtyGroups: SchoolSpecialtyGroup[];
  areas: Area[],
  prefectures: Prefecture[],
  onSubmit: () => void;
  onClose: () => void;
  title: string;
}

const ScheduleForm: FC<FormProps> = (props) => {
  const {
    formKey,
    formData,
    setFormData,
    errors,
    targetPrefectures,
    setTargetPrefectures,
    schoolSpecialtyGroups,
    areas,
    prefectures,
    onSubmit,
    onClose,
    title
  } = props;

  const onChange = (value: RequestData[keyof RequestData], key: keyof RequestData): void => {
    const data = { ...formData, [key]: value }
    setFormData(data);
  }

  const setTargetPrefectureIds = (item: Item<number>): void => {
    const data = { ...formData }
    data.targetPrefectureIds.push(item.value);
    setFormData(data);
  }

  const removeTargetPrefectureIds = (item: Item<number>): void => {
    const data = { ...formData }
    data.targetPrefectureIds = data.targetPrefectureIds.filter(prefectureId => prefectureId != item.value)
    setFormData(data);
  }

  const setTargetSchoolSpecialtyGroups = (isChecked: boolean, value: number) => {
    const data = { ...formData }

    if(isChecked) {
      data.targetSchoolSpecialtyGroupIds.push(value);
    } else {
      data.targetSchoolSpecialtyGroupIds = data.targetSchoolSpecialtyGroupIds.filter(id => id != value);
    }

    setFormData(data);
  }

  return (
    <Panel>
      <PanelTitle title={ title }/>
      <Form className='col-6'>
        <Form.Group>
          <Form.Label>エリア</Form.Label>
          <Form.Control
            as="select"
            custom
            onChange={ e => onChange(e.target.value, 'areaId') }
            value={ formData.areaId || '' }
            isInvalid={ errors.areaId && errors.areaId.length > 0 }
          >
            <option></option>
            {
              areas.map((area: Area) => {
                return (
                  <option
                    key={ `area-${area.id}` }
                    value={ area.id }
                  >
                    { area.name }
                  </option>)
              })
            }
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            { errors.areaId?.map((err, i) => <p key={ `error-areaId-${i}` }>{ err }</p>) }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='scheduleFormIsSchedule'>
          <Form.Label>日程有無</Form.Label>
          <div className={ `` }>
            <Form.Check
              inline
              id='isScheduleTrue'
              type={ 'radio' }
              name='isSchedule'
              label='有'
              onChange={ _e => onChange(true, 'isSchedule') }
              checked={ formData.isSchedule }
            />
            <Form.Check
              inline
              id='isScheduleFalse'
              type={ 'radio' }
              name='isSchedule'
              label='無'
              onChange={ _e => onChange(false, 'isSchedule') }
              checked={ !formData.isSchedule }
            />
          </div>
        </Form.Group>

        { formData.isSchedule ? (
          <>
            <Form.Group>
              <Form.Label>開始日時</Form.Label>
              <DateTime
                key={ `startAt-${formKey}` }
                className={`col-6 p-0`}
                isInvalid={ errors.startAt && errors.startAt?.length > 0 }
                name='startAt'
                onChange={ value => onChange(value, "startAt") }
                value={ formData.startAt || '' }
              />
              <span className="invalid-feedback">
                { errors.startAt?.map((err, i) => <p key={ `error-startAt-${i}` }>{ err }</p>) }
              </span>
            </Form.Group>

            <Form.Group>
              <Form.Label>終了日時</Form.Label>
              <DateTime
                key={ `endAt-${formKey}` }
                className={`col-6 p-0`}
                isInvalid={ errors.endAt && errors.endAt?.length > 0 }
                name='endAt'
                onChange={ value => onChange(value, "endAt") }
                value={ formData.endAt }
              />
              <span className="invalid-feedback">
                { errors.endAt?.map((err, i) => <p key={ `error-endAt-${i}` }>{ err }</p>) }
              </span>
            </Form.Group>
          </>
        ) : (
          <Form.Group>
            <Form.Label>日程無しテキスト</Form.Label>
            <Form.Control
              type='text'
              name="noScheduleText"
              onChange={ e => onChange(e.target.value, 'noScheduleText') }
              value={ formData.noScheduleText || '' }
              isInvalid={ errors.noScheduleText && errors.noScheduleText?.length > 0 }
            />
            <Form.Control.Feedback type="invalid">
              { errors.noScheduleText?.map((err, i) => <p key={ `error-noScheduleText-${i}` }>{ err }</p>) }
            </Form.Control.Feedback>
          </Form.Group>
        )}

        <Form.Group controlId='scheduleFormIsSchedule'>
          <Form.Label>開催タイプ</Form.Label>
          <div>
            <Form.Check
              inline
              id='isOnline'
              type={ 'radio' }
              name='isOnline'
              label='オフライン'
              checked={ !formData.isOnline }
              onChange={ _e => onChange(false, 'isOnline') }
            />
            <Form.Check
              inline
              id='isOffline'
              type={ 'radio' }
              name='isOnline'
              label='オンライン'
              checked={ formData.isOnline }
              onChange={ _e => onChange(true, 'isOnline') }
            />
          </div>
        </Form.Group>

        { formData.isOnline ? (
          <Form.Group>
            <Form.Label>オンラインテキスト(Web会議URL等)</Form.Label>
            <Form.Control
              as='textarea'
              name="onlineText"
              onChange={ e => onChange(e.target.value, 'onlineText') }
              value={ formData.onlineText }
              isInvalid={ errors.onlineText && errors.onlineText?.length > 0 }
            />
            <Form.Control.Feedback type="invalid">
              { errors.onlineText?.map((err, i) => <p key={ `error-onlineText-${i}` }>{ err }</p>) }
            </Form.Control.Feedback>
        </Form.Group>
        ) : (
          <>
            <Form.Group>
              <Form.Label>郵便番号</Form.Label>
              <Form.Control
                type='text'
                name="postCode"
                onChange={ e => onChange(e.target.value, 'postCode') }
                value={ formData.postCode }
                isInvalid={ errors.postCode && errors.postCode?.length > 0 }
              />
              <Form.Control.Feedback type="invalid">
                { errors.postCode?.map((err, i) => <p key={ `error-postCode-${i}` }>{ err }</p>) }
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>都道府県</Form.Label>
              <Form.Control
                as="select" custom
                onChange={ e => onChange(e.target.value, 'prefectureId') }
                value={ formData.prefectureId || '' }
                isInvalid={ errors.prefectureId && errors.prefectureId?.length > 0 }
              >
                <option></option>
                {
                  prefectures.map((prefecture: Prefecture) => {
                    return (
                      <option
                        key={ `prefecture-${prefecture.id}` }
                        value={ prefecture.id }
                      >
                        { prefecture.name }
                      </option>)
                  })
                }
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                { errors.prefectureId?.map((err, i) => <p key={ `error-prefectureId-${i}` }>{ err }</p>) }
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>住所1</Form.Label>
              <Form.Control
                type='text'
                name="address_1"
                onChange={ e => onChange(e.target.value, 'address_1') }
                value={ formData.address_1 }
                isInvalid={ errors.address1 && errors.address1?.length > 0 }
              />
              <Form.Control.Feedback type="invalid">
                { errors.address1?.map((err, i) => <p key={ `error-address1-${i}` }>{ err }</p>) }
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>住所2</Form.Label>
              <Form.Control
                type='text'
                name="address_2"
                onChange={ e => onChange(e.target.value, 'address_2') }
                value={ formData.address_2 }
                isInvalid={ errors.address2 && errors.address2?.length > 0 }
              />
              <Form.Control.Feedback type="invalid">
                { errors.address2?.map((err, i) => <p key={ `error-address2-${i}` }>{ err }</p>) }
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>最寄り駅</Form.Label>
              <Form.Control
                type='text'
                name="station"
                onChange={ e => onChange(e.target.value, 'station') }
                value={ formData.station }
                isInvalid={ errors.station && errors.station?.length > 0 }
              />
              <Form.Control.Feedback type="invalid">
                { errors.station?.map((err, i) => <p key={ `error-station-${i}` }>{ err }</p>) }
              </Form.Control.Feedback>
            </Form.Group>
          </>
        )}

        <Form.Group>
          <Form.Label>備考</Form.Label>
          <Form.Control
            as='textarea'
            name="remarks"
            onChange={ e => onChange(e.target.value, 'remarks') }
            value={ formData.remarks }
            isInvalid={ errors.remarks && errors.remarks?.length > 0 }
          />
          <Form.Control.Feedback type="invalid">
            { errors.remarks?.map((err, i) => <p key={ `error-remarks-${i}` }>{ err }</p>) }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>参加上限</Form.Label>
          <Form.Control
            type='number'
            name="participantLimit"
            onChange={ e => onChange(e.target.value, 'participantLimit') }
            value={ formData.participantLimit }
            isInvalid={ errors.participantLimit && errors.participantLimit?.length > 0 }
          />
          <Form.Control.Feedback type="invalid">
            { errors.participantLimit?.map((err, i) => <p key={ `error-participantLimit-${i}` }>{ err }</p>) }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>公開開始日時</Form.Label>
          <DateTime
            key={ `openAt-${formKey}` }
            className={`col-6 p-0`}
            name='openAt'
            onChange={ value => onChange(value, "openAt") }
            value={ formData.openAt }
            isInvalid={ errors.openAt && errors.openAt?.length > 0 }
          />
          <span className="invalid-feedback">
            { errors.openAt?.map((err, i) => <p key={ `error-openAt-${i}` }>{ err }</p>) }
          </span>
        </Form.Group>

        <Form.Group>
          <Form.Label>公開終了日時</Form.Label>
          <DateTime
            key={ `closeAt-${formKey}` }
            className={`col-6 p-0`}
            name='closeAt'
            onChange={ value => onChange(value, "closeAt") }
            value={ formData.closeAt }
            isInvalid={ errors.closeAt && errors.closeAt?.length > 0 }
          />
          <span className="invalid-feedback">
            { errors.closeAt?.map((err, i) => <p key={ `error-closeAt-${i}` }>{ err }</p>) }
          </span>
        </Form.Group>

        <Form.Group controlId='scheduleFormTargetShooleSpecialityGroups'>
          <Form.Label>文理制限</Form.Label>
          <div>
            {
              schoolSpecialtyGroups && schoolSpecialtyGroups.map(group => {
                return (
                  <Form.Check
                    key={ `school-specialty-groups-${group.id}`}
                    inline
                    id={ group.specialtyGroup }
                    type={ 'checkbox' }
                    name='targetSchoolSpecialtyGroups'
                    label={ group.label }
                    checked={ formData.targetSchoolSpecialtyGroupIds.includes(group.id) }
                    onChange={ e => setTargetSchoolSpecialtyGroups(e.target.checked, group.id) }
                  />
                )
              })
            }
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label>都道府県制限</Form.Label>
          <Selectize
            id={ 'prefectures' }
            items={ targetPrefectures }
            setItems={ setTargetPrefectures }
            onRemove={ removeTargetPrefectureIds }
            onSelect={ setTargetPrefectureIds }
            placeholder={ '都道府県を入力してください' }
            isInvalid={ errors.targetPrefectureIds && errors.targetPrefectureIds?.length > 0 }
          />
          <Form.Control.Feedback type="invalid">
            { errors.targetPrefectureIds?.map((err, i) => <p key={ `error-targetPrefectureIds-${i}` }>{ err }</p>) }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='scheduleFormIsSchedule'>
          <Form.Label>公開</Form.Label>
          <div>
            <Form.Check
              inline
              id='isPublishedTrue'
              type={ 'radio' }
              name='isPublished'
              label='公開'
              checked={ formData.isPublished }
              onChange={ _e => onChange(true, 'isPublished') }
            />
            <Form.Check
              inline
              id='isPublishedFalse'
              type={ 'radio' }
              name='isPublished'
              label='非公開'
              checked={ !formData.isPublished }
              onChange={ _e => onChange(false, 'isPublished') }
            />
          </div>
        </Form.Group>

        <Form.Group>
          <Button type="button" className='mr-2' variant="outline-secondary" onClick={ onClose }>キャンセル</Button>
          <Button type="button" variant='outline-success' onClick={ onSubmit }>保存</Button>
        </Form.Group>
      </Form>
    </Panel>
  )
}

export default ScheduleForm;