import { FC, Dispatch } from 'react';
import Selectize, { Item } from 'components/Selectize';
import { Button, Form } from 'react-bootstrap';
import {
  CreateData,
  CreateErrors,
  UpdateData,
  UpdateErrors
} from 'libs/apiClient/admin/educationalBackgroundGroups';

type FormProps = {
  formData: CreateData | UpdateData;
  setFormData: Dispatch<React.SetStateAction<CreateData | UpdateData>>;
  errors: CreateErrors | UpdateErrors;
  schools: Item<number[]>[];
  setSchools: Dispatch<React.SetStateAction<Item<number[]>[]>>;
  onSubmit: () => void;
}

const EducationalBackgroundGroupForm: FC<FormProps>= (props) => {
  const {
    formData,
    setFormData,
    errors,
    schools,
    setSchools,
    onSubmit
  } = props;

  const onChange = (value: string, key: 'name'): void => {
    const data = { ...formData }
    data[key] = value;

    setFormData(data);
  }

  const setSchoolIds = (item: Item<number[]>): void => {
    const data = { ...formData }
    const schoolIds = item.value;
    data.schoolIds = data.schoolIds.concat(schoolIds)

    setFormData(data);
  }

  const removeSchoolIds = (item: Item<number[]>): void => {
    const data = { ...formData }
    const schoolIds = item.value;
    data.schoolIds = data.schoolIds.filter(schoolId => !schoolIds.includes(schoolId))

    setFormData(data);
  }

  return (
    <Form noValidate>
      <Form.Group controlId='formBasicName'>
        <Form.Label>学歴グループ名</Form.Label>
        <Form.Control
          type='text'
          name="name"
          onChange={ e => onChange(e.currentTarget.value, "name") }
          defaultValue={ formData.name }
          isInvalid={ errors.name?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.name?.map((err, i) => <p key={ `error-name-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='formBasicName'>
        <Form.Label>大学・大学院・短期大学</Form.Label>
        <Selectize
          id={ 'schools' }
          items={ schools }
          setItems={ setSchools }
          onRemove={ removeSchoolIds }
          onSelect={ setSchoolIds }
          placeholder={ '学校名を入力してください' }
          isInvalid={ errors.schoolIds?.length > 0 }
        />
        <Form.Control.Feedback type="invalid">
          { errors.schoolIds?.map((err, i) => <p key={ `error-schoolIds-${i}` }>{ err }</p>) }
        </Form.Control.Feedback>
      </Form.Group>

      <Button type="button" onClick={ onSubmit } variant='outline-success'>
        登録
      </Button>
    </Form>
  )
}

export default EducationalBackgroundGroupForm;
