import { FC, useEffect, useState } from "react";
import Layout from "layouts/Layout";
import PageTitle from "components/PageTitle";
import Panel from "components/Panel";
import Selectize, { Item } from "components/Selectize";
import DateTime from "components/DateTime";
import { Breadcrumb, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  index as getEducationalBackgroundGroups,
  EducationalBackgroundGroup,
} from "libs/apiClient/admin/educationalBackgroundGroups";
import { index as getTags, Tag } from "libs/apiClient/admin/tags";
import "moment/locale/ja";
import {
  showPath,
  updatePath,
  Campaign,
  CampaignType,
  OutsideCampaign,
  CustomCampaign,
  RequestData,
  Errors,
  Response,
} from "libs/apiClient/admin/campaigns";
import { get, patch } from "libs/apiClient";
import { showCompaniesPathCreator } from "routes/companies/path";
import { newEducationalBackgroundGroupsPath } from "routes/educationalBackgroundGroups/path";
import { newTagsPath } from "routes/tags/path";
import Forbidden from "pages/forbidden";

type urlProps = {} & RouteComponentProps<{ companyId: string; id: string }>;
type Year = {
  year: number;
  month: number;
};

const Edit: FC<urlProps> = (props) => {
  const history = useHistory();
  const [campaign, setCampaign] = useState<Campaign>();
  const [formData, setFormData] = useState<RequestData>();
  const [errors, setErrors] = useState<Errors>({});
  const [campaignType, setCampaignType] = useState<CampaignType>();
  const [educationalBackgroundGroups, setEducationalBackgroundGroups] =
    useState<Item<number>[]>([]);
  const [tags, setTags] = useState<Item<number>[]>([]);
  const [graduationYears, setGraduationYears] = useState<Item<Year>[]>([]);
  const [isForbidden, setIsForbidden] = useState<Boolean>(false);

  const forbiddenCallback = () => {
    setIsForbidden(true);
  };

  useEffect(() => {
    get(
      showPath(props.match.params.id),
      "campaign",
      (res: Campaign) => {
        const newFormData: RequestData = {
          companyId: res.companyId,
          type: res.type as CampaignType,
          name: res.campaignable.name,
          memo: res.campaignable.memo,
          startAt: res.campaignable.startAt,
          closeAt: res.campaignable.closeAt,
          graduationYears: res.graduationYears.map((year: Year) => ({
            year: year.year,
            month: year.month,
          })),
          tags: res.tags.map((tag: Tag) => tag.id),
          educationalBackgroundGroups: res.educationalBackgroundGroups.map(
            (eduBackGroup: { id: number; name: string }) => eduBackGroup.id
          ),
        };

        if (newFormData.type == "outside") {
          const campaignable = res.campaignable as OutsideCampaign;
          newFormData.redirectUrl = campaignable.redirectUrl;
        }

        if (newFormData.type == "custom") {
          const campaignable = res.campaignable as CustomCampaign;
          newFormData.customHtml = campaignable.customHtml;
          newFormData.middlewareId = campaignable.middleware.id;
          newFormData.redirectUrl = campaignable.redirectUrl;
        }

        setCampaignType(newFormData.type);
        setCampaign(res);
        // タグ取得 & selectize用に整形
        getTags((res: Tag[]) => {
          const newTags = res.map((tag) => {
            return {
              id: tag.id,
              label: tag.name,
              value: tag.id,
              isDisplay: true,
              isSelected: newFormData.tags.includes(tag.id) || false,
            };
          });

          setTags(newTags);
        });
        // 学歴グループ取得
        getEducationalBackgroundGroups((res: EducationalBackgroundGroup[]) => {
          const newEducationalBackgroundGroups = res.map(
            (educationalBackgroundGroup) => {
              return {
                id: educationalBackgroundGroup.id,
                label: educationalBackgroundGroup.name,
                value: educationalBackgroundGroup.id,
                isDisplay: true,
                isSelected:
                  newFormData.educationalBackgroundGroups.includes(
                    educationalBackgroundGroup.id
                  ) || false,
              };
            }
          );

          setEducationalBackgroundGroups(newEducationalBackgroundGroups);
        });

        // 卒年
        const targetYears = [0, 1, 2, 3, 4, 5].map(
          (n) => new Date().getFullYear() + n
        );
        const yearTemplate = (
          id: number,
          year: number,
          month: number
        ): Item<Year> => {
          const selectedYear = newFormData.graduationYears.find(
            (yearObj: { year: number; month: number }) => {
              return yearObj.year == year && yearObj.month == month;
            }
          );

          return {
            id: id,
            label: `${year}年`,
            value: { year: year, month: month },
            isDisplay: true,
            isSelected: Boolean(selectedYear),
          };
        };

        const newYears: Item<Year>[] = targetYears.map((year, i) => {
          return yearTemplate(i + 1, year, 3);
        });

        setGraduationYears(newYears);
        setFormData(newFormData);
      },
      forbiddenCallback
    );
  }, []);

  const onChange = (
    value: RequestData[keyof RequestData],
    key: keyof RequestData
  ) => {
    const newFormData = { ...formData, [key]: value };
    setFormData(newFormData as RequestData);
  };

  const onSubmit = () => {
    const callback = (_res: Response) => {
      history.push(showCompaniesPathCreator(props.match.params.companyId));
    };

    const errCallback = (err: Errors) => {
      setErrors(err);
    };

    patch(
      updatePath(props.match.params.id),
      { campaign: formData },
      "campaign",
      callback,
      errCallback,
      forbiddenCallback
    );
  };

  const Page = () => {
    return (
      <Layout>
        <Breadcrumb>
          <Breadcrumb.Item href="/companies">企業一覧</Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: `/companies/${formData?.companyId}` }}
          >
            企業ID: {formData?.companyId}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {campaign?.campaignable.name}
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageTitle title="キャンペーン編集" />
        <Panel className="col-lg-6 col-md-12 col-sm-12">
          {formData && (
            <Form noValidate>
              <Form.Group controlId="campaignType">
                <Form.Label>キャンペーンタイプ</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  onChange={(e) => {
                    onChange(e.target.value, "type");
                    setCampaignType(e.target.value as CampaignType);
                  }}
                  isInvalid={errors.type && errors.type?.length > 0}
                  defaultValue={formData.type}
                >
                  <option value="inside">インサイド</option>
                  <option value="outside">アウトサイド</option>
                  <option value="custom">カスタム</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.type?.map((err, i) => (
                    <p key={`error-type-${i}`}>{err}</p>
                  ))}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="campaignName">
                <Form.Label>キャンペーン名</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  defaultValue={formData.name}
                  onChange={(e) => onChange(e.target.value, "name")}
                  isInvalid={errors.name && errors.name?.length > 0}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.map((err, i) => (
                    <p key={`error-name-${i}`}>{err}</p>
                  ))}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="campaignMemo">
                <Form.Label>メモ</Form.Label>
                <Form.Control
                  as="textarea"
                  name="memo"
                  defaultValue={formData.memo}
                  onChange={(e) => onChange(e.target.value, "memo")}
                  isInvalid={errors.memo && errors.memo?.length > 0}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.memo?.map((err, i) => (
                    <p key={`error-memo-${i}`}>{err}</p>
                  ))}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="startAt">
                <Form.Label>開始日時</Form.Label>
                <DateTime
                  className={`col-6 p-0`}
                  isInvalid={errors.startAt && errors.startAt?.length > 0}
                  value={formData.startAt}
                  name="startAt"
                  onChange={(value) => onChange(value, "startAt")}
                />
                <span className="invalid-feedback">
                  {errors.startAt?.map((err, i) => (
                    <p key={`error-startAt-${i}`}>{err}</p>
                  ))}
                </span>
              </Form.Group>

              <Form.Group controlId="closeAt">
                <Form.Label>終了日時</Form.Label>
                <DateTime
                  className={`col-6 p-0`}
                  isInvalid={errors.closeAt && errors.closeAt?.length > 0}
                  value={formData.closeAt}
                  name="closeAt"
                  onChange={(value) => onChange(value, "closeAt")}
                />
                <span className="invalid-feedback">
                  {errors.closeAt?.map((err, i) => (
                    <p key={`error-closeAt-${i}`}>{err}</p>
                  ))}
                </span>
              </Form.Group>

              <Form.Group controlId="campaignGraduationYears">
                <Form.Label>卒年</Form.Label>
                <Selectize
                  id={"years"}
                  items={graduationYears}
                  setItems={setGraduationYears}
                  onRemove={(item) => {
                    const value = [
                      ...{ ...formData }["graduationYears"],
                    ].filter((graduationYear) => {
                      const isYear = graduationYear.year == item.value.year;
                      const isMonth = graduationYear.month == item.value.month;

                      return !(isYear && isMonth);
                    });
                    onChange(value, "graduationYears");
                  }}
                  onSelect={(item) => {
                    const value = [
                      ...{ ...formData }["graduationYears"],
                      item.value,
                    ];
                    onChange(value, "graduationYears");
                  }}
                  placeholder={"卒年を選択してください"}
                  isInvalid={
                    errors.graduationYears && errors.graduationYears?.length > 0
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.graduationYears?.map((err, i) => (
                    <p key={`error-years-${i}`}>{err}</p>
                  ))}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="campaignSchoolGroups">
                <Form.Label className="col-12 p-0 d-flex justify-content-between">
                  学歴グループ
                  <a href={newEducationalBackgroundGroupsPath}>
                    学歴グループを追加する
                  </a>
                </Form.Label>
                <Selectize
                  id={"schools"}
                  items={educationalBackgroundGroups}
                  setItems={setEducationalBackgroundGroups}
                  onRemove={(item) => {
                    const value = [
                      ...{ ...formData }["educationalBackgroundGroups"],
                    ].filter((id) => !(id == item.value));
                    onChange(value, "educationalBackgroundGroups");
                  }}
                  onSelect={(item) => {
                    const value = [
                      ...{ ...formData }["educationalBackgroundGroups"],
                      item.value,
                    ];
                    onChange(value, "educationalBackgroundGroups");
                  }}
                  placeholder={"学歴グループを入力してください"}
                  isInvalid={
                    errors.educationalBackgroundGroups &&
                    errors.educationalBackgroundGroups?.length > 0
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.educationalBackgroundGroups?.map((err, i) => (
                    <p key={`error-edus-${i}`}>{err}</p>
                  ))}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="campaignTags">
                <Form.Label className="col-12 p-0 d-flex justify-content-between">
                  タグ
                  <a href={newTagsPath}>タグを追加する</a>
                </Form.Label>
                <Selectize
                  id={"tags"}
                  items={tags}
                  setItems={setTags}
                  onRemove={(item) => {
                    const value = [...{ ...formData }["tags"]].filter(
                      (id) => !(id == item.value)
                    );
                    onChange(value, "tags");
                  }}
                  onSelect={(item) => {
                    const value = [...{ ...formData }["tags"], item.value];
                    onChange(value, "tags");
                  }}
                  placeholder={"タグを入力してください"}
                  isInvalid={errors.tags && errors.tags?.length > 0}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.tags?.map((err, i) => (
                    <p key={`error-tags-${i}`}>{err}</p>
                  ))}
                </Form.Control.Feedback>
              </Form.Group>

              {campaignType == "custom" && (
                <>
                  <Form.Group controlId="campaignMiddleware">
                    <Form.Label>ミドルウェア</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      isInvalid={
                        errors.middlewareId && errors.middlewareId?.length > 0
                      }
                      onChange={(e) => onChange(e.target.value, "middlewareId")}
                      value={formData.middlewareId}
                    >
                      <option></option>
                      {/* TODO : サーバーからミドルウェア一覧を取得し、optionに追加する */}
                      <option value="1">doda</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.middlewareId?.map((err, i) => (
                        <p key={`error-middleware-${i}`}>{err}</p>
                      ))}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="customHtml">
                    <Form.Label>カスタムHTML(利用規約リンクなど)</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="customHtml"
                      placeholder="<a href='https://...'>利用規約</a>"
                      isInvalid={
                        errors.customHtml && errors.customHtml?.length > 0
                      }
                      onChange={(e) => onChange(e.target.value, "customHtml")}
                      value={formData.customHtml}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.customHtml?.map((err, i) => (
                        <p key={`error-cutomHtml-${i}`}>{err}</p>
                      ))}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="redirectUrl">
                    <Form.Label>リダイレクトURL</Form.Label>
                    <Form.Control
                      type="text"
                      name="redirect_url"
                      placeholder="https://..."
                      isInvalid={
                        errors.redirectUrl && errors.redirectUrl?.length > 0
                      }
                      onChange={(e) => onChange(e.target.value, "redirectUrl")}
                      value={formData.redirectUrl}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.redirectUrl?.map((err, i) => (
                        <p key={`error-redirectUrl-${i}`}>{err}</p>
                      ))}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}

              {campaignType == "outside" && (
                <Form.Group controlId="redirectUrl">
                  <Form.Label>リダイレクトURL</Form.Label>
                  <Form.Control
                    type="text"
                    name="redirect_url"
                    placeholder="https://..."
                    isInvalid={
                      errors.redirectUrl && errors.redirectUrl?.length > 0
                    }
                    onChange={(e) => onChange(e.target.value, "redirectUrl")}
                    value={formData.redirectUrl}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.redirectUrl?.map((err, i) => (
                      <p key={`error-redirectUrl-${i}`}>{err}</p>
                    ))}
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <Button
                type="button"
                variant="outline-success"
                onClick={onSubmit}
              >
                保存
              </Button>
            </Form>
          )}
        </Panel>
      </Layout>
    );
  };

  if (isForbidden) {
    return <Forbidden />;
  } else {
    return Page();
  }
};

export default Edit;
