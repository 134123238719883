import { Prefecture } from 'libs/apiClient/prefectures';
import { School } from 'libs/apiClient/schools';
import { SchoolSpecialtyGroup } from 'libs/apiClient/schoolSpecialtyGroups';

export const updatePath = (id: string | number) => `api/v1/admin/inside_sales/users/${id}`

export type User = {
  userId: number;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  email: string;
  phoneNumber: number | string;
  graduateYear: number;
  graduateMonth: number;
  prefecture: Prefecture;
  gender: string;
  schoolSpecialtyGroup: SchoolSpecialtyGroup;
  school: School;
  jobHuntingStatus: string;
  isEmailAvailable: boolean;
  isPhoneAvailable: boolean;
}

export type RequestData = {
  graduateYear: number | null;
  gender: string;
  schoolSpecialtyGroupId: number | null;
  schoolId: number | null;
  jobHuntingStatus: string;
  isEmailAvailable: boolean;
  isPhoneAvailable: boolean;
}

export type Response = {
  id: number | string;
}

export type Errors = {
  graduateYear?: string[];
  prefectureId?: string[];
  gender?: string[];
  schoolSpecialtyGroupId?: string[];
  schoolId?: string[];
}
