import { FC, useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import {
  indexPath,
  createPath,
  User,
  RequestData,
  Errors,
  Response
} from 'libs/apiClient/admin/users';
import {
  updatePath,
  RequestData as UpdateRequestData,
  Errors as UpdateErrors,
  Response as UpdateResponse
} from 'libs/apiClient/admin/insideSales/users';
import { get, create, update } from 'libs/apiClient';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import Table from 'components/Table';
import { Item } from 'components/Selectize';
import { Prefecture, indexPath as PrefecturesPath } from 'libs/apiClient/prefectures';
import { School, indexPath as SchoolsPath } from 'libs/apiClient/schools';
import { SchoolSpecialtyGroup, indexPath as SchoolSpecialtyGroupsPath } from 'libs/apiClient/schoolSpecialtyGroups';
import { Area, indexPath as AreasPath } from 'libs/apiClient/admin/areas';
import UserCreateFormInsideCampaign from './UserCreateForm/UserCreateFormInsideCampaign';
import UserCreateFormOutsideCampaign from './UserCreateForm/UserCreateFormOutsideCampaign';
import UserCreateFormDoNotCall from './UserCreateForm/UserCreateFormDoNotCall';
import UserUpdateForm from './UserUpdateForm';
import Entry from '../entries';
import Schedule from '../schedules';
import Toast from 'components/Toast';

type Year = {
  year: number;
  month: number;
};

const Users: FC = () => {
  const formDataTemplate = {
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    email: '',
    phoneNumber: '',
    graduateYear: 2023,
    graduateMonth: 3,
    prefectureId: null,
    gender: '',
    schoolSpecialtyGroupId: null,
    schoolId: null,
    jobHuntingStatus: 'active',
    isEmailAvailable: true,
    isPhoneAvailable: true,
    isSendPasswordInstruction: true
  }
  const formUpdateDataTemplate = {
    graduateYear: null,
    prefectureId: null,
    gender: '',
    schoolSpecialtyGroupId: null,
    schoolId: null,
    jobHuntingStatus: 'active',
    isEmailAvailable: true,
    isPhoneAvailable: true
  }
  const targetSchoolDataTemplate = {
    id: 0,
    name: '',
    degree: '',
    faculty: '',
    department: '',
    searchKey: '',
  }
  const [formCreateData, setFormCreateData] = useState<RequestData>(formDataTemplate);
  const [formUpdateData, setFormUpdateData] = useState<UpdateRequestData>(formUpdateDataTemplate);
  const [errors, setErrors] = useState<Errors>({});
  const [updateErrors, setUpdateErrors] = useState<UpdateErrors>({});
  const [isDispayCreateFormModal, setIsDispayCreateFormModal] = useState<boolean>(false);
  const [displayCreateFormTypeFormData, setDisplayCreateFormTypeFormData] = useState<string | null>('insideCampaign');
  const [displayCreateFormType, setDisplayCreateFormType] = useState<string | null>(null);
  const [isDisplayUpdateForm, setIsDisplayUpdateForm] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>();
  const [users, setUsers] = useState<User[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string | number | null>(null);
  const [areas, setAreas] = useState<Area[]>([]);
  const [prefectures, setPrefectures] = useState<Prefecture[]>([]);
  const [schools, setSchools] = useState<School[]>([]);
  const [schoolSpecialtyGroups, setSchoolSpecialtyGroups] = useState<SchoolSpecialtyGroup[]>([]);
  const [graduationYears, setGraduationYears] = useState<Item<Year>[]>([]);
  const [targetSchoolCreateData, setTargetSchoolCreateData] = useState<School>(targetSchoolDataTemplate);
  const [targetSchoolUpdateData, setTargetSchoolUpdateData] = useState<School>(targetSchoolDataTemplate);
  const [isDispayToast, setIsDispayToast] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    // 文理マスターの取得
    get(SchoolSpecialtyGroupsPath, 'schoolSpecialtyGroups', setSchoolSpecialtyGroups);

    // 都道府県マスターの取得
    get(PrefecturesPath, 'prefectures', setPrefectures);

    // 卒年マスターの取得
    const targetYears = [0, 1, 2, 3, 4, 5].map(n => new Date().getFullYear() + n)
    const yearTemplate = (id: number, year: number, month: number): Item<Year> => {
      return {
        id: id,
        label: `${year}年`,
        value: { year: year, month: month },
        isDisplay: true,
        isSelected: false
      }
    }
    const newYears: Item<Year>[] = targetYears.map((year, i) => {
      return yearTemplate(i + 1, year, 3);
    })
    setGraduationYears(newYears);

    // 学校マスターの取得
    get(SchoolsPath, 'schools', setSchools);
  }, []);

  const onCreate = () => {
    setIsDispayCreateFormModal(true);
    setDisplayCreateFormTypeFormData('insideCampaign');
    setIsDisplayUpdateForm(false);
  }

  const onSubmitCreateFormModal = () => {
    setIsDispayCreateFormModal(false);
    setDisplayCreateFormType(displayCreateFormTypeFormData)
    setDisplayCreateFormTypeFormData('insideCampaign');
    setFormCreateData(formDataTemplate);
    setTargetSchoolCreateData(targetSchoolDataTemplate);
    setUser(null);
    setErrors({});
  }

  const onCloseModal = () => {
    setIsDispayCreateFormModal(false);
    setDisplayCreateFormType(null);
  }

  const onCloseCreateForm = () => {
    setDisplayCreateFormType(null);
    setFormCreateData(formDataTemplate);
    setTargetSchoolCreateData(targetSchoolDataTemplate);
  }

  const onSubmitCreateForm = () => {
    const callback = (_res: Response) => {
      setMessage('ユーザーを作成しました');
      setIsDispayToast(true);
      setDisplayCreateFormType(null);
      setFormCreateData(formDataTemplate);
      setTargetSchoolCreateData(targetSchoolDataTemplate);
      setErrors({});
    }

    const errCallback = (err: Errors) => {
      setErrors(err)
    }

    create(createPath, { user: { ...formCreateData } }, 'user', callback, errCallback);
  }

  const onCloseUpdateForm = () => {
    setIsDisplayUpdateForm(false);
    setFormUpdateData(formDataTemplate);
    setTargetSchoolUpdateData(targetSchoolDataTemplate);
    setUser(null);
  }

  const onSubmitUpdateForm = () => {
    const callback = (_res: UpdateResponse) => {
      setMessage('ユーザー情報を更新しました');
      setIsDispayToast(true);
      setIsDisplayUpdateForm(false);
      setFormUpdateData(formDataTemplate);
      setTargetSchoolUpdateData(targetSchoolDataTemplate);
      setUser(null);
      setUpdateErrors({});
      // 更新したユーザー情報を取得
      get(indexPath(searchKeyword), 'users', setUsers);
    }

    const errCallback = (err: Errors) => {
      setUpdateErrors(err)
    }

    user && user.userId && update(updatePath(user.userId), { user: { ...formUpdateData } }, 'user', callback, errCallback);
  }

  const onSearch = () => {
    const callback = (res: User[]) => {
      setUsers(res);
      if (res.length == 0) {
        setMessage('該当するユーザーは見つかりませんでした');
        setIsDispayToast(true);
      }
    }

    get(indexPath(searchKeyword), 'users', callback)
  }

  const onUpdate = (user: User) => {
    setIsDisplayUpdateForm(true);
    setDisplayCreateFormType(null);

    setFormUpdateData({
      graduateYear: user.graduateYear,
      gender: user.gender,
      schoolSpecialtyGroupId: user.schoolSpecialtyGroup && user.schoolSpecialtyGroup.id,
      schoolId: user.school && user.school.id,
      jobHuntingStatus: user.jobHuntingStatus,
      isEmailAvailable: user.isEmailAvailable,
      isPhoneAvailable: user.isPhoneAvailable
    })
    const targetSchool = {
      id: user.school && user.school.id,
      name: user.school && user.school.name,
      degree: user.school && user.school.degree,
      faculty: user.school && user.school.faculty,
      department: user.school && user.school.department,
      searchKey: user.school && user.school.searchKey,
    };
    setTargetSchoolUpdateData(targetSchool);
    setUser(user);
    setUpdateErrors({});
  }

  return (
    <>
      <Panel className='pb-4'>
        <PanelTitle title='ユーザー検索'>
          <Button variant="primary" onClick={_e => onCreate()}>新規作成</Button>
        </PanelTitle>
        <Form className='col-6'>
          <Form.Group controlId='userSearch'>
            <Form.Label>メールアドレス or 電話番号</Form.Label>
            <Form.Control
              type='text'
              name="userSearch"
              onChange={e => setSearchKeyword(e.target.value)}
            />
          </Form.Group>
          <Button type="button" variant='outline-primary' onClick={onSearch}>検索</Button>
        </Form>
      </Panel>

      <Panel className='pb-4'>
        <PanelTitle title='検索結果' />
        <Table className='mb-2'>
          <thead>
            <tr>
              <th>ID</th>
              <th>姓</th>
              <th>名</th>
              <th>メールアドレス</th>
              <th>電話番号</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {users && (
              users.map(user => {
                return (
                  <tr key={user.userId}>
                    <td className={`align-middle`} >{user.userId}</td>
                    <td className={`align-middle`} >{user.lastName}</td>
                    <td className={`align-middle`} >{user.firstName}</td>
                    <td className={`align-middle`} >{user.email}</td>
                    <td className={`align-middle`} >{user.phoneNumber}</td>
                    <td className={`align-middle`} >
                      <Button
                        variant='dark'
                        onClick={() => onUpdate(user)}
                      >
                        詳細
                      </Button>
                    </td>
                  </tr>
                )
              })
            )}
          </tbody>
        </Table>
        {
          isDisplayUpdateForm && user && (
            <>
              <UserUpdateForm
                formData={formUpdateData}
                setFormData={setFormUpdateData}
                errors={updateErrors}
                schools={schools}
                schoolSpecialtyGroups={schoolSpecialtyGroups}
                prefectures={prefectures}
                graduationYears={graduationYears}
                targetSchool={targetSchoolUpdateData}
                setTargetSchool={setTargetSchoolUpdateData}
                user={user}
                onSubmit={onSubmitUpdateForm}
                onClose={onCloseUpdateForm}
              />

              <Entry
                userId={user.userId}
              />

              <Schedule
                userId={user.userId}
              />
            </>
          )
        }
      </Panel>
      {
        displayCreateFormType === 'insideCampaign' && (
          <UserCreateFormInsideCampaign
            formData={formCreateData}
            setFormData={setFormCreateData}
            errors={errors}
            areas={areas}
            schools={schools}
            schoolSpecialtyGroups={schoolSpecialtyGroups}
            prefectures={prefectures}
            graduationYears={graduationYears}
            targetSchool={targetSchoolCreateData}
            setTargetSchool={setTargetSchoolCreateData}
            onSubmit={onSubmitCreateForm}
            onClose={onCloseCreateForm}
          />
        )
      }
      {
        displayCreateFormType === 'outsideCampaign' && (
          <UserCreateFormOutsideCampaign
            formData={formCreateData}
            setFormData={setFormCreateData}
            errors={errors}
            areas={areas}
            schools={schools}
            schoolSpecialtyGroups={schoolSpecialtyGroups}
            prefectures={prefectures}
            graduationYears={graduationYears}
            targetSchool={targetSchoolCreateData}
            setTargetSchool={setTargetSchoolCreateData}
            onSubmit={onSubmitCreateForm}
            onClose={onCloseCreateForm}
          />
        )
      }
      {
        displayCreateFormType === 'doNotCall' && (
          <UserCreateFormDoNotCall
            formData={formCreateData}
            setFormData={setFormCreateData}
            errors={errors}
            areas={areas}
            schools={schools}
            schoolSpecialtyGroups={schoolSpecialtyGroups}
            prefectures={prefectures}
            graduationYears={graduationYears}
            targetSchool={targetSchoolCreateData}
            setTargetSchool={setTargetSchoolCreateData}
            onSubmit={onSubmitCreateForm}
            onClose={onCloseCreateForm}
          />
        )
      }

      <Modal show={isDispayCreateFormModal} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>フォームタイプ</Modal.Title>
        </Modal.Header>
        <Modal.Body>          
          <Form.Group controlId='createFormType'>
            <Form.Label>登録フォームを選択してください</Form.Label>
            <>
              <Form.Control
                as="select"
                custom
                onChange={ e => {setDisplayCreateFormTypeFormData(e.target.value)} }
              >
                <option value='insideCampaign'>イベント登録</option>
                <option value='outsideCampaign'>媒体登録</option>
                <option value='doNotCall'>電話停止登録</option>
              </Form.Control>
            </>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCloseModal}>
            閉じる
          </Button>
          <Button variant="primary" onClick={() => onSubmitCreateFormModal()}>
            作成
          </Button>
        </Modal.Footer>
      </Modal>

      {isDispayToast && (
        <Toast
          message={message}
          isDispayToast={isDispayToast}
          setIsDispayToast={setIsDispayToast}
        />
      )
      }
    </>
  )
}

export default Users;
